import axios from 'axios';
import { getAccessToken } from './azureAd'

export default class BaseService {

  apiUrlBase = process.env.REACT_APP_URL_BASE;

  DbAprovadores = 'apprat_aprovadores';
  DbClassesEquip = 'apprat_classes_equip';
  DbCategorias = 'apprat_categorias';
  DbPergunta = 'apprat_perguntas';

  DbRelatorioTemp = 'apprat_relatorio_temp';

  DbRelatorio = 'apprat_relatorio';
  DbFileNamePrefix = 'apprat_file_name_';

  constructor(route) {
    this.route = route;
  }
  
  getAxiosClient (timeout = 10000, token = '') {
    return axios.create({
      baseURL: this.apiUrlBase,
      timeout: timeout,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': token
      },
      withCredentials: true
    });
  }

  axiosCreate(action, data, model, db) {
    return getAccessToken().then((t) => { 
      return this.getAxiosClient(10000, t.accessToken)
        .post(action, data)
        .then((response) => {
          model.id = response.data._id;
          this.insertDb(db, model, false);
          return Promise.resolve(true);
        })
        .catch((error) => Promise.reject(error));
    });
    
  }
  
  axiosUpdate(action, data, model, db) {
    return getAccessToken().then((t) => { 
      return this.getAxiosClient(10000, t.accessToken)
        .put(action, data)
        .then((response) => {
          this.updateDb(db, model);
          return Promise.resolve(true);
        })
        .catch((error) => Promise.reject(error));
    });
    
  }
  
  axiosDelete(action, id, db) {
    return getAccessToken().then((t) => { 
      return this.getAxiosClient(10000, t.accessToken)
        .delete(action, {})
        .then((response) => {
          this.deleteDb(db, id);
          return Promise.resolve(true);
        })
        .catch((error) => Promise.reject(error));
    });
  }

  listAllActive(dbName) {
    return this.getDb(dbName).filter(m => !m.deleted);
  }

  getDb(dbName) {
    var db = localStorage.getItem(dbName);
    if (db != null && db != '') {
      return JSON.parse(db);
    }
    return [];
  }

  replaceDb(dbName, obj) {
    //marcar para sync com o server
    if (obj != null) {
      if (Array.isArray(obj)) {
        for (var idx = 0; idx < obj.length; idx++) {
          obj[idx].to_sync = true;
        }
      } else {
        obj.to_sync = true;
      }
    }
    localStorage.setItem(dbName, JSON.stringify(obj));
  }

  findByIdDb(dbName, id) {
    return this.getDb(dbName).find(m => m.id == id && !m.deleted);
  }

  insertDb(dbName, obj, insertId) {
    //marcar para sync com o server
    var db = this.getDb(dbName);
    obj.deleted = false;
    obj.to_sync = insertId;
    if (insertId) {
      //esses cadastros básicos provavelmente serão enviados em realtime, deixando o trabalho offline apenas para o preenchimento das inspeções
      obj.id = Date.now();
    }
    db.push(obj);
    localStorage.setItem(dbName, JSON.stringify(db));

    return obj.id;
  }

  updateDb(dbName, obj) {
    //marcar para sync com o server
    var db = this.getDb(dbName);
    obj.to_sync = true;
    obj.deleted = false;
    //esses cadastros básicos provavelmente serão enviados em realtime, deixando o trabalho offline apenas para o preenchimento das inspeções


    if (db.some(i => i.id === obj.id)){
      db.splice(db.findIndex(i => i.id === obj.id),1);
    }

    db.push(obj);
    localStorage.setItem(dbName, JSON.stringify(db));

    return obj.id;
  }

  deleteDb(dbName, id) {
    var db = this.getDb(dbName);
    for (var idx = 0; idx < db.length; idx++) {
      if (db[idx].id == id) {
        db[idx].deleted = true;
        break;
      }
    }
    localStorage.setItem(dbName, JSON.stringify(db));
  }

  uuidv4 () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  storeFile (fileName, base64file, fnc = (v) => { }) {
    var uuid = this.uuidv4();
    var db;
    var request = window.indexedDB.open("filesDb", 2);

    request.onerror = function (event) {
      console.log("error: ");
    };

    request.onsuccess = function (event) {
      db = request.result;
      
      var request2 = db.transaction(["files"], "readwrite")
        .objectStore("files")
        .add({ uuid: uuid, file: base64file });

      request2.onerror = function (event) {
        alert("Erro para adicionar o arquivo no banco de dados local.");
        db.close();
      }
      
      request2.onsuccess = (evt) => {
        fnc(uuid)
        db.close();
      }
    };

    request.onupgradeneeded = function (event) {
      console.log('onupgradeneeded ini')
      var db = event.target.result;
      db.createObjectStore("files", { keyPath: "uuid" });
      console.log('onupgradeneeded end')
    }

    var keyName = `${this.DbFileNamePrefix}${uuid}`;
    localStorage.setItem(keyName, fileName); 
    return uuid;
  }

  getFiles(keys, fnc) {
    var db;
    var request = window.indexedDB.open("filesDb", 2);

    request.onerror = (event) => {
      console.log("error: ");
    };

    request.onsuccess = (event) => {
      db = request.result;

      var transaction = db.transaction(["files"]);
      var objectStore = transaction.objectStore("files");
      
      Promise.all(
        keys.map(
          (key) =>
            new Promise((resolve, reject) => {
              const request2 = objectStore.get(key);
              request2.onsuccess = ({ target: { result } }) => resolve(result);
              request2.onerror = ({ target: { error } }) => reject(error);
            })
        )
      ).then((values) => {
        fnc(
          keys.reduce(
            (result, key, index) => ((result[key] = values[index]), result), {}
          )
        );
      });
    };

    request.onupgradeneeded = function (event) {
      var db = event.target.result;
      db.createObjectStore("files", { keyPath: "uuid" });
    }
  }

  getFile(key, fnc) {

    var db;
    var request = window.indexedDB.open("filesDb", 2);

    request.onerror = (event) => {
      console.log("error: ");
    };

    request.onsuccess = (event) => {
      db = request.result;

      var transaction = db.transaction(["files"]);
      var objectStore = transaction.objectStore("files");
      var request2 = objectStore.get(key);

      request2.onerror = function (event) {
        var result =  Promise.reject(event);
        db.close();
        return result;
      };

      request2.onsuccess = (event) => {
        // Do something with the request.result!
        if (request2.result) {
          fnc(request2.result.file);
          db.close();
        } 
      }
    };

    request.onupgradeneeded = function (event) {
      var db = event.target.result;
      db.createObjectStore("files", { keyPath: "uuid" });
    }
  }

  deleteFile (key, fnc = () => {}) {
    var db;
    var request = window.indexedDB.open("filesDb", 2);

    request.onerror = function (event) {
      console.log("error: ");
    };

    request.onsuccess = function (event) {
      db = request.result;

      var request2 = db.transaction(["files"], "readwrite")
        .objectStore("files")
        .delete(key);

      request2.onerror = function (event) {
        alert("Erro para adicionar o arquivo no banco de dados local.");
        db.close();
      }

      request2.onsuccess = (evt) => {
        fnc();
        db.close();
      }
    };

    request.onupgradeneeded = function (event) {
      console.log('onupgradeneeded ini')
      var db = event.target.result;
      db.createObjectStore("files", { keyPath: "uuid" });
      console.log('onupgradeneeded end')
    }
    localStorage.removeItem(`${this.DbFileNamePrefix}${key}`);
  }

  updateFile (key, base64File, fnc = () => { }) {
    var db;
    var request = window.indexedDB.open("filesDb", 2);

    request.onerror = function (event) {
      console.log("error: ");
    };

    request.onsuccess = function (event) {
      db = request.result;

      var request2 = db.transaction(["files"], "readwrite")
        .objectStore("files")
        .put({ uuid: key, file: base64File });

      request2.onerror = function (event) {
        alert("Erro para adicionar o arquivo no banco de dados local.");
        db.close();
      }

      request2.onsuccess = (evt) => {
        fnc();
        db.close();
      }
    };

    request.onupgradeneeded = function (event) {
      console.log('onupgradeneeded ini')
      var db = event.target.result;
      db.createObjectStore("files", { keyPath: "uuid" });
      console.log('onupgradeneeded end')
    }
  }

  

}
