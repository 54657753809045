
import BaseService  from './baseService.js';
import { getAccessToken } from './azureAd'

export default class ListAllService extends BaseService {

    constructor() {
        super('list-all');
    }

    listAll() {
        return getAccessToken().then((t) => {
            return this.getAxiosClient(10000, t.accessToken)
                .get('carregar-parametros', {})
                .then((response) => {
                    this.saveAllLists(response.data)
                    return Promise.resolve(true);
                });
        });
    }

    saveAllLists (data) { 
        localStorage.removeItem(this.DbClassesEquip);
        localStorage.removeItem(this.DbCategorias);
        localStorage.removeItem(this.DbPergunta);
        localStorage.removeItem(this.DbAprovadores);

        localStorage.setItem(this.DbClassesEquip, 
            JSON.stringify(
                data.classes.map((m) => { return { id: m._id, nome: m.descricao } })
            )
        );
        localStorage.setItem(this.DbCategorias, 
            JSON.stringify(
                data.categorias.map((m) => { return { id: m._id, nome: m.descricao } })
            )
        );
        localStorage.setItem(this.DbAprovadores,
            JSON.stringify(
                data.aprovadores.map((m) => { return { email: m.email, nome: m.nome } })
            )
        );
        localStorage.setItem(this.DbPergunta,
            JSON.stringify(
                data.perguntas.map((m) => { 
                    return { 
                        id: m._id, 
                        descricao: m.descricao, 
                        eliminatoria: m.caraterEliminatorio ? 'true' : 'false',
                        metodo: m.metodoAnalise,
                        evidencias: m.evidencias,
                        categoriaid: m.categoriaId
                    } 
                })
            )
        );
    }

}
