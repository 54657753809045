import React, { Component } from 'react';

import Button from "@material-ui/core/Button";

import { Editor } from './EditorComponent/index';

import '../../../common/Main.css';
import ActionBar from './ActionBar';

import RelatorioService from '../../../../services/relatorioService.js';
let relatorioService = new RelatorioService();

const calcularWH = (width, height, windowinnerWidth, windowinnerHeight) => {
  if (width > 1024 || height > 768) {
    var oRatio = width / height;
    var cRatio = windowinnerWidth / windowinnerHeight;
    var thisWidth;
    var thisHeight;

    if (oRatio > cRatio) {
      thisWidth = windowinnerWidth;
      thisHeight = windowinnerWidth / oRatio;
    } else {
      thisWidth = windowinnerHeight * oRatio;
      thisHeight = windowinnerHeight;
    }
    return { w: thisWidth, h: thisHeight };
  }
  return { w: width, h: height };
}

class EditorDialog extends Component {
  editor;

  relatorioid;
  categoriaid;
  perguntaid;
  fotoid;

  constructor(props) { 
    super(props);

    this.relatorioid = props.match.params.relatorioid;
    this.categoriaid = props.match.params.categoriaid;
    this.perguntaid = props.match.params.perguntaid;
    this.fotoid = props.match.params.fotoid;

    this.editor = React.createRef();
    
    this.state = {
      colors: ['black', 'red', 'green', 'yellow', 'blue', 'pink', 'orange', 'purple', 'crimson'],
      croppingImage: false,
      mode: '',
      isRebuild: false,
      w: 0,
      h: 0
    };
  }

  componentDidMount () { 
    relatorioService.getFoto(this.fotoid, (imgBase64) => {
      var img = new Image();
      img.src = imgBase64;
      if (img.complete) { 
        var wh = calcularWH(img.width, img.height, window.innerWidth, (window.innerHeight - 100));
        this.setState({ ['w']: wh.w });
        this.setState({ ['h']: wh.h });
        setTimeout(() => { this.editor.current.setBackgroundImage(imgBase64); }, 650)
      } else {
        img.onload = () => {
          var wh = calcularWH(img.width, img.height, window.innerWidth, (window.innerHeight - 100));
          this.setState({ ['w']: wh.w });
          this.setState({ ['h']: wh.h });
          setTimeout(() => { this.editor.current.setBackgroundImage(imgBase64); }, 650)
        }
      }
    });
  }

  handleSave() {
    var imgBase64 = this.editor.current.saveImage();
    relatorioService
      .salvarFotoPergunta(relatorioService.findById(this.relatorioid), this.categoriaid, this.perguntaid, this.fotoid, imgBase64, () => {
        this.handleClose()
      });
  }

  handleClose () {
    window.onorientationchange = null;
    this.props.history.push({
      pathname: `/inspecao/pergunta/${this.relatorioid}/${this.categoriaid}/${this.perguntaid}`
    });
  }

  setMode(mode) {
    this.state.mode = mode;
    var options = {};
    switch(mode) {
      case 'text':
        options.placeholder = 'Texto';
        break;
      default:
        options.strokeWidth = 5;
        break;
    }
    this.editor.current.set(mode, options);
  }

  render () { 
    return (
      <div style={{ overflow: 'auto' }}>

        <div style={{ maxWidth: '100vw', maxHeight: '100vh', overflow: 'auto', textAlign: 'center' }}>
        {
            this.state.h == 0 ? null : <Editor ref={this.editor} style={{ margin: 'auto', overflow: 'hidden' }} width={this.state.w} height={this.state.h} />
        }
          
        </div>

        <ActionBar editor={this.editor}></ActionBar>

        <div style={{ textAlign: "center", marginTop: '10px' }}>
          <Button onClick={() => { this.handleSave() }} variant="contained" color="primary" style={{ marginLeft: '5px', marginRight: '5px' }}>
            Salvar
          </Button>
          <Button onClick={() => { this.handleClose() }} variant="contained" color="secondary" style={{ marginLeft: '5px', marginRight: '5px' }}>
            Cancelar
          </Button>
        </div>

      </div>
    );
  }

}

export default EditorDialog;