import React, { Component } from 'react';
import '../../common/Main.css';
import { withStyles } from '@material-ui/core/styles';
import Header from '../../common/Header';
import Input from '../../common/Input';
import FormButton from '../../common/FormButton';
import RelatorioService from '../../../services/relatorioService.js';

import { defaultValue } from '../../../helpers';

import { Grid } from '@material-ui/core';


let service = new RelatorioService();

const styles = theme => ({
  root: {
    '& > *': {
      width: '100%',
    },
    width: '100%',
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  labelIcon: {
    padding: 3,
    marginRight: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
    fill: "#cbd44b",
    fontSize: 18,
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
  },
  textField: {
    paddingTop: 10,
    width: '100%',
  },
  checkBox: {
    position: 'relative',
    textAlign: 'left',
    paddingTop: 30,
  },
  checkBoxLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  radioChecked: {
    color: '#cbd44b',
    '&$checked': {
      color: '#cbd44b',
    },
  },
  radioNotChecked: {
    color: '#c9c9c9',
    '&$checked': {
      color: '#c9c9c9',
    },
  },
  input: {
    color: "#cbd44b"
  },
  iconClose: {
    margin: 5,
    marginLeft: 'auto',
    height: 30,
    width: 30,
    padding: 5,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
  }
});

class Form extends Component {
  id = null;
  constructor(props) {
    super(props);
    var model;
    if (this.props.match.params.id && this.props.match.params.id != null) {
      this.id = this.props.match.params.id;
      model = service.findById(this.props.match.params.id);
    } else {
      model = service.getTemp();
    }
    this.state = {
      nome: defaultValue(model, 'nome'),
      codigo: defaultValue(model, 'codigo'),
      ende: defaultValue(model, 'ende'),
      contato: defaultValue(model, 'contato'),
      fone: defaultValue(model, 'fone'),
      email: defaultValue(model, 'email'),
      especificacao: defaultValue(model, 'especificacao'),
      error: false,
      ...this.props.location.state
    }
  }

  validate = () => {
    return this.state.nome != "" &&
      this.state.codigo != "" &&
      this.state.ende != "" &&
      this.state.contato != "" &&
      this.state.fone != "" &&
      this.state.email != '' &&
      this.state.especificacao != '';
  };

  setValue = (name, value) => {
    this.setState({[name]: value });
  }

  handleSave = () => {
    if (this.validate()) {
      var url = '/inspecao/iniciar/step3';
      this.setState(prevState => ({ ...prevState, 'error': false }))
      if (this.id == null) {
        service.updateTempParte2(this.state);
      } else {
        url += `/${this.id}`;
        service.updateStep2(this.id, this.state);
        
      }
      this.props.history.push({
        pathname: url,
        state: this.state
      })

    } else {
      this.setState(prevState => ({ ...prevState, 'error': true }))
    }
  }
  
  getBackUrl () {
    var url = '/inspecao/iniciar/step1';
    if (this.id != null) { url += `/${this.id}` }
    return url;
  }

  render(){
    const { classes } = this.props;
    return (
      <div>
        <Header progress="60" title="Cadastro de Relatório" subtitle="Parte 2/3 - Dados do Fornecedor" backTo={this.getBackUrl()} />
        
        <Grid container item xs={12} lg={12}>
          <Grid container item xs={11} lg={8} className="margin-auto">
            <form className={classes.root} noValidate autoComplete="off">
              <Input number={'01'} name="nome" label="Qual o nome do fornecedor?" setValue={this.setValue} value={this.state.nome} required={true} error={this.state.error} />
              <Input number={'02'} name="codigo" label="Qual o código do fornecedor?" setValue={this.setValue} value={this.state.codigo} required={true} error={this.state.error} />
              <Input number={'03'} name="ende" label="Qual o endereço do fornecedor?" setValue={this.setValue} value={this.state.ende} required={true} error={this.state.error} />
              <Input number={'04'} name="contato" label="Qual o contato (ponto focal) do fornecedor?" setValue={this.setValue} value={this.state.contato} required={true} error={this.state.error} />
              <Input number={'05'} name="fone" label="Qual o telefone do contato?" setValue={this.setValue} value={this.state.fone} required={true} error={this.state.error} />
              <Input number={'06'} name="email" label="Qual o e-mail do contato?" setValue={this.setValue} value={this.state.email} required={true} error={this.state.error} />
              <Input number={'07'} name="especificacao" label="Qual a Especificação Técnica Unificada - ETU (versão, data) está sendo baseado no relatório?" setValue={this.setValue} value={this.state.especificacao}
                required={true} error={this.state.error} />
            </form>
            <FormButton text={ 'Próximo' } clicked={this.handleSave} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Form);
