import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import { Grid } from "@material-ui/core";

import '../../common/Main.css';
import './pdf.css';
import Header from '../../common/Header';
import FormButton from '../../common/FormButton';
import PdfDoc from './PdfDoc';

import { pdf } from '@react-pdf/renderer'

import { MobilePDFReader } from 'react-read-pdf';

import RelatorioService from '../../../services/relatorioService.js';
let relatorioService = new RelatorioService();


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '100%',
    },
    width: '100%',
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
}));


function Preview () {
  const { id } = useParams();
  const classes = useStyles();
  let history = useHistory();

  const [state, setState] = useState({
    relatorio: null,
    blob: null,
    anexos: [],
    fotos: {}
  });

  const [loadedState, loadedSetState] = useState(false);
  
  

  const getCategoriaCalculo = (categoria) => {
    var max = categoria.perguntas.reduce((acumlador, pergunta) => {
      if (pergunta.nota == 'NA') {
        return acumlador + 0;
      }
      if (pergunta.eliminatoria == 'true') {
        return acumlador + (3 * 5);
      }
      return acumlador + 5;
    }, 0);
    var notaEmp = categoria.perguntas.reduce((acumlador, pergunta) => {
      if (pergunta.nota == 'NA') {
        return acumlador + 0;
      }
      if (pergunta.eliminatoria == 'true') {
        return acumlador + (3 * Number.parseInt(pergunta.nota));
      }
      return acumlador + Number.parseInt(pergunta.nota);
    }, 0);
    if (max > 0) {
      return Math.round((100.0 / Number.parseFloat(max)) * Number.parseFloat(notaEmp));
    }
    return null;
  }

  const getCalculoGeral = (relatorio) => {
    var total = 0;
    var qtd = 0;
    relatorio.categorias.forEach(cat => {
      var vl = getCategoriaCalculo(cat);
      if (vl != null) {
        total += vl;
        qtd++;
      }
    });
    return Math.round(total / qtd)
  }
  
  const getStatus = (relatorio) => {
    var total = getCalculoGeral(relatorio);
    if (total < 60 || hasEliminatoriaZerada(relatorio)) {
      return 'R';
    }
    if (total < 80) {
      return 'AR'
    }
    return 'A';
  }

  /*
  00 - 59 - Reprovado
  60 - 79 - Aprovado com restrição
  80 - 100 - Aprovado
  */
  const hasEliminatoriaZerada = (relatorio) => {
    var hasEliminatoria = false;
    relatorio.categorias.forEach(cat => {
      if (cat.perguntas.some(m => m.nota == '0' && m.eliminatoria == 'true')) {
        hasEliminatoria = true;
      }
    });
    return hasEliminatoria;
  }


  if (!loadedState) {
    loadedSetState(true);
    var relatorio = relatorioService.findById(id);
    var anexos = [];
    var imagens = [];
    relatorio.codigosHomologados.forEach(f => {
      imagens.push(f);
    })
    
    anexos.push({
      catId: '',
      perId: '',
      key: relatorio.file_apr,
      name: relatorioService.getFileName(relatorio.file_apr)
    })
    relatorio.categorias.forEach(cat => {
      cat.perguntas.forEach(p => {
        p.anexos.forEach(a => {
          anexos.push({
            catId: cat.id,
            perId: p.id,
            key: a,
            name: relatorioService.getFileName(a)
          })
        });
        p.fotos.forEach(f => { 
          imagens.push(f);
        });
      })
    });
    
    relatorio.anexos.forEach(a => {
      anexos.push({
        catId: '',
        perId: '',
        key: a,
        name: relatorioService.getFileName(a)
      })
    });

    relatorioService.getFiles(imagens.map(m => m.key), (fotos) => {
      imagens.forEach(m => { 
        fotos[m.key].width = m.width;
        fotos[m.key].height = m.height;
      });
      setState(prevState => ({ ...prevState, 'fotos': fotos }));
      setState(prevState => ({ ...prevState, 'anexos': anexos }));
      setState(prevState => ({ ...prevState, 'relatorio': relatorio }));
      
      pdf(<PdfDoc 
          getCategoriaCalculo={getCategoriaCalculo}
          getCalculoGeral={getCalculoGeral}
          getStatus={getStatus}
          relatorio={relatorio} 
          fotos={fotos} 
          anexos={anexos}></PdfDoc>).toBlob().then((r) => {
        var reader = new FileReader();
        reader.onload = function (event) {
          setState(prevState => ({ ...prevState, 'blob': event.target.result }));
        };
        reader.readAsDataURL(r);
      });
    
    })
  }

  const gerarEnviar = () => {
    state.relatorio.categorias.forEach((cat) => { 
      cat.nota = getCategoriaCalculo(cat); 
    })
    state.relatorio.nota = getCalculoGeral(state.relatorio);
    state.relatorio.status = getStatus(state.relatorio);
    relatorioService.gerarRelatorio(state.relatorio, state.blob, (key) => {
      history.push({
        pathname: `/inspecao/enviar-relatorio/${id}`
      });
    });
  }

  const downloadPDF = () => {
    const byteCharacters = atob(state.blob.substring(state.blob.indexOf(',') + 1));
    
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);


    var blob = new Blob([byteArray], { type: 'application/pdf' });
    //window.location.assign(window.URL.createObjectURL(blob));
    var link = window.document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'relatorio_'+state.relatorio.nome+'.pdf';
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    link.remove();
    window.URL.revokeObjectURL(link.href);
  }

  return (
    <div>
      <Header progress="100" title="Preview" backTo={`/inspecao/conclusao/${id}`}/>
      <Grid container item xs={12} lg={12}>
        <Grid container item xs={11} lg={8} className="margin-auto">
          <form className={classes.root} height={(window.innerHeight - 250)}>
            <div style={{ height: (window.innerHeight - 250), overflow: 'auto'}}>
              {
                state.blob != null ?
                  (
                    <MobilePDFReader url={state.blob} isShowHeader={false} isShowFooter={false} />
                  ) : <h5 style={{width: '100%', textAlign: 'center'}}>Aguarde... Gerando PDF</h5>
              }
            </div>
          </form>
          {
            state.blob != null ?
              (<div style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                    <FormButton text={'Enviar Para Aprovação'} clicked={() => gerarEnviar()} />
                </div>
                <div style={{width: '100%'}}>
                  <FormButton text={'Download PDF'} clicked={() => downloadPDF()} />
                </div>
              </div>) : null
          }
        </Grid>
      </Grid>
    </div>
  );
}

export default Preview;