import React from 'react';
import ReactDOM from 'react-dom';
import '../common/Main.css';
import './Home.css';
import { Grid, Box, Card } from '@material-ui/core';
import Menu from '../common/Menu';
import Footer from '../common/Footer';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Alerta from '../common/Alert';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as NewFile } from './../../svg/new-file.svg';
import { ReactComponent as FileSearch } from './../../svg/file-search.svg';
import { ReactComponent as FileLeft } from './../../svg/file-left.svg';


const useStyles = makeStyles({
  newReport: {
    //minWidth: 400,
    marginLeft: 4,
    marginRight: 4,
  },
  lastReport: {
    marginLeft: 4,
    marginRight: 4,
  },
  selectReport: {
    marginLeft: 4,
    marginRight: 4,
  },
});

function App() {
  let history = useHistory();
  const classes = useStyles();

  let handleNovoRelatorioClick = () => {
    history.push('/inspecao/iniciar/step1')
  };
  
  let handleRelatoriosClick= () => {
    history.push('/relatorios')
  };
  
  let handleUltimoRelatorioClick = () => {
    var id = localStorage.getItem('apprat_ultimo_relatorio');
    if (id != null) {
      history.push(`/inspecao/${id}`);
    } else {
      ReactDOM.render(<Alerta severity={'warning'} message={'Não há um último relatório registrado no seu dispositivo.'} />, document.querySelector('#snack_root'));
    }
    
  };
  
  let userName = () => {
    return JSON.parse(localStorage.getItem('apprat_userinfo')).displayName;
  };
  

  return (
    <div className="App">
      <Grid>
        <Grid className="header-inner" container item={true} xs={12} lg={12}>
          <Grid className="header" container item={true} xs={11} lg={8} >
            <img src="./assets/logo.png" className="logo" />
            <Menu />
          </Grid>
        </Grid>


        <Grid
          container
          item={true}
          lg={12}
          xs={12}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ paddingTop: '50px'}}
        >
        
          <Grid container item={true} xs={11} lg={6} justify="center">
            <Grid item={true} xs={12} lg={12} >
              <Box component="span" display="block" className="text">
                Olá { userName() }, seja bem vindo ao app RAT
            </Box>
            </Grid>
          </Grid>
          
          <Grid container item={true} xs={11} lg={6} justify="center" style={{ paddingTop: '40px' }}>
            <Grid item={true} xs={12} lg={12}>
              <Card className={classes.newReport} style={{cursor: 'pointer'}} onClick={handleNovoRelatorioClick}>
                <CardContent>
                  <NewFile style={{ fontSize: '40px' }}/>
                  <Typography className={classes.pos} color="textSecondary">
                    Novo Relatório
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container item={true} xs={11} lg={6} justify="center">
            <Grid item={true} xs={6} md={6} lg={6} style={{ paddingTop: '20px' }}>
              <Card className={classes.lastReport} style={{ cursor: 'pointer' }} onClick={handleUltimoRelatorioClick}>
                <CardContent>
                  <FileLeft style={{ fill: "#cbd44b", fontSize: '40px' }}/>
                  <Typography className={classes.pos} color="textSecondary">
                    Último Relatório
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item={true} xs={6} md={6} lg={6} style={{ paddingTop: '20px' }}>
              <Card className={classes.selectReport} style={{ cursor: 'pointer' }} onClick={handleRelatoriosClick}>
                <CardContent>
                  <FileSearch style={{ fill: "#cbd44b", fontSize: '40px' }}/>
                  <Typography className={classes.pos} color="textSecondary">
                    Relatórios
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer/>
    </div>
  );
}

export default App;
