import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Swal from 'sweetalert2';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import CreateIcon from '@material-ui/icons/Create';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import '../common/Main.css';

import Header from '../common/Header';
import RelatorioService from '../../services/relatorioService';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 15,
    paddingRight: 15,
    maginTop: 20,
    backgroundColor: '#F9F9F9',
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  icon: {
    padding: 5,
    margingRight: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
    fill: "#cbd44b",
    fontSize: 20,
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
  },
  divider: {
    minWidth: '100%'
  },
  listItem: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: 'solid 1px #c9c9c9'
  },

  description: {
    maxWidth: '80%',
    color: '#808080'
  }
}));


function ListRelatorios() {

  const classes = useStyles();
  let history = useHistory();
  const [state, setState] = React.useState({
    elements: []
  });

  let service = new RelatorioService()
  
  React.useEffect(() => {
    service.listAll().then((result) => {
      setState({
        elements: result
      });
    });
  }, []);

  const edit = (id) => {
    history.push({ pathname: `/inspecao/${id}`})
  };
  const enviar = (id) => {
    history.push({ pathname: `/inspecao/enviar-relatorio/${id}` })
  };
  const showPDF = (id) => {
    history.push({ pathname: `/inspecao/preview/${id}` })
  };
  const del = (i) => {
    Swal.fire({
      title: '',
      text: 'Deseja excluir o relatório do seu dispositivo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        service.deleteRelatorio(state.elements[i]);
        state.elements.splice(i, 1);
        setState(prevState => ({ ...prevState, ['elements']: state.elements }));
      }
    })
  }

  return (
    <div className="App">
      <Header progress="100" title="Relatórios" backTo="/home"/>
      
      <Grid container item xs={12} lg={12}>
        <Grid item xs={11} lg={8} className="margin-auto">

          <div className={classes.container}>
            {
              state.elements.length > 0 &&
              <List dense={false}>
                {
                  state.elements.map((el, idx) =>
                    React.cloneElement(
                      <div>
                        <ListItem className={classes.listItem}>
                          <ListItemText className={classes.description}
                            primary={`${el.tipoelab} - ${el.nome}`} />
                          <ListItemSecondaryAction>
                            {
                              el.status && el.status != '' ? (
                                <IconButton edge="end" onClick={() => {
                                  enviar(el.id)
                                }}>
                                  <SendIcon className={classes.icon} />
                                </IconButton>
                              ) : (null)
                              
                            }
                            {
                              el.status && el.status != '' ? (
                                <IconButton edge="end" onClick={() => {
                                  showPDF(el.id)
                                }}>
                                  <PictureAsPdfIcon className={classes.icon} />
                                </IconButton>
                              ) : (null)

                            }
                            <IconButton edge="end" onClick={() => {
                              edit(el.id)
                            }}>
                              <CreateIcon className={classes.icon} />
                            </IconButton>
                            <IconButton edge="end" onClick={() => {
                              del(idx)
                            }}>
                              <CloseIcon className={classes.icon} />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </div>
                      , {
                        key: el.id
                      })
                  )
                }
              </List>
            }
            {
              state.elements.length == 0 &&
              <div className="text-center" style={{ "fontSize": "1.4em", "padding": "30px" }}>Sem itens para exibição</div>
            }

          </div>

        </Grid>

      </Grid>
      
    </div>
  );
}

export default ListRelatorios;
