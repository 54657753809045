
import BaseService from './baseService.js';

export default class CheckOnlineService extends BaseService {

  constructor() {
    super('checkOnline');
  }

  checkIsOnline () {
    return this.getAxiosClient(5000)
      .get('check-online', {});
  }

}