import React, { useState } from 'react';
import '../common/Main.css';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../common/Header';
import Input from '../common/Input';
import FormButton from '../common/FormButton';
import Radio from '@material-ui/core/Radio';
import PerguntaService from '../../services/perguntaService.js';
import InputLabel from '@material-ui/core/InputLabel';
import CheckIcon from '@material-ui/icons/Check';
import Alerta from '../common/Alert';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import ReactDOM from 'react-dom';

let service = new PerguntaService();

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '100%',
    },
    width: '100%',
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  labelIcon: {
    padding: 3,
    marginRight: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
    fill: "#cbd44b",
    fontSize: 18,
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
  },
  textField: {
    paddingTop: 10,
    width: '100%',
  },
  checkBox: {
    position: 'relative',
    textAlign: 'left',
    paddingTop: 30,
  },
  checkBoxLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  radioChecked: {
    color: '#cbd44b',
    '&$checked': {
      color: '#cbd44b',
    },
  },
  radioNotChecked: {
    color: '#c9c9c9',
    '&$checked': {
      color: '#c9c9c9',
    },
  },
  btnAddEvidencia: {
    borderRadius: 5,
    backgroundColor: "#cbd44b",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "5px",
    paddingLeft: "5px",
    fontSize: "1.2em",
    width: '100%',
    '&:hover': {
      backgroundColor: '#cbd44b',
    },
    color: "#ffffff", textTransform: "capitalize",
  },
  inputEvidencia: {
    marginBottom: 30,
    width: '100%',
    background: '#ffffff',
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
    border: '0px',
  },
  input: {
    color: "#cbd44b"
  },
  iconClose: {
    margin: 5,
    marginLeft: 'auto',
    height: 30,
    width: 30,
    padding: 5,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
  }
}));

function Form(props) {
  const classes = useStyles();
  const { id, categoriaid } = props.match.params;

  const [state, setState] = useState({
    id: 0,
    descricao: "",
    peso: "",
    eliminatoria: "",
    metodo: "",
    evidencias: [''],
    categoriaid: categoriaid,
    isSaving: false
  });
  const [loadedState, loadedSetState] = useState(false);
  const [error, setError] = useState(false);


  if (id != null && id != "undefined" && !loadedState) {
    loadedSetState(true);
    var model = service.findById(id);
    setState(prevState => ({ ...prevState, 'id': model.id }));
    setState(prevState => ({ ...prevState, 'descricao': model.descricao }));
    setState(prevState => ({ ...prevState, 'eliminatoria': model.eliminatoria ? 'true' : 'false' }));
    setState(prevState => ({ ...prevState, 'metodo': model.metodo }));
    setState(prevState => ({ ...prevState, 'evidencias': model.evidencias }));
    setState(prevState => ({ ...prevState, 'categoriaid': model.categoriaid }));
  }

  let setValue = (name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }));
  }
  
  const handlePromisse = (promise) => {
    promise
      .then((response) => handleSuccess())
      .catch((error) => {
        setState(prevState => ({ ...prevState, 'isSaving': false }));
        ReactDOM.render(<Alerta severity={'error'} message={`Erro ao tentar salvar: ${error.message}`} />, document.querySelector('#snack_root'));
      });
  }

  const validate = () => {
    return state.descricao != '' && state.eliminatoria != '' && state.metodo != '' && state.evidencias.length > 0;
  }

  const handleSuccess = () => {
    props.history.push("/perguntas");
    setState(prevState => ({ ...prevState, 'isSaving': false }));
    ReactDOM.render(<Alerta severity={'success'} message={'Registro ' + (state.id == 0 ? 'criado' : 'alterado') + ' com sucesso.'} />, document.querySelector('#snack_root'));
  }

  const handleSave = () => {

    if (validate()) {
      setState(prevState => ({ ...prevState, 'isSaving': true }));
      if (state.id == 0) {
        handlePromisse(service.create(state));
      } else {
        handlePromisse(service.update(state));
      }
    } else {
      setError(true);
    }
  }

  const handleChangeRadio = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleChangeEvidencia = (idx, event) => {
    const target = event.target;
    const value = target.value;
    state.evidencias[idx] = value
    setState(prevState => ({ ...prevState, 'evidencias': state.evidencias }));
  };

  const radio = () => {
    let value = state.eliminatoria;
    let icon = <CheckIcon className={classes.labelIcon} style={{ fill: value != null && value.toString().trim() == '' ? "#696969" : "#cbd44b" }} />;
    return <div className={classes.checkBox}>
      <InputLabel className={classes.label}>
        { icon }
        A pergunta possui caráter eliminatório?
      </InputLabel>
      <div style={{ paddingTop: 30, paddingLeft: 30 }}>
        <Radio
          className={value == 'true' ? classes.radioChecked : classes.radioNotChecked}
          checked={ value == 'true'}
          onChange={handleChangeRadio}
          value="true"
          color="#cbd44b"
          name="eliminatoria"
          inputProps={{ 'aria-label': 'E' }}
          size="small"
        />
        <span className={classes.checkBoxLabel}>Sim</span>
        <Radio
          className={value == 'false' ? classes.radioChecked : classes.radioNotChecked}
          checked={value == 'false'}
          onChange={handleChangeRadio}
          value="false"
          color="#cbd44b"
          name="eliminatoria"
          inputProps={{ 'aria-label': 'E' }}
          size="small"
        />
        <span className={classes.checkBoxLabel}>Não</span>
      </div>
      <InputLabel
        className={classes.textField}
        name="eliminatoriaText"
        error={error}
        InputProps={{
          className: classes.input
        }}
      >
        {(error && value != null && value.trim() == '' ? 'Campo obrigatório' : ' ') }
      </InputLabel>
    </div>;
  }

  const addEvidencia = () => {
    state.evidencias.push('');
    setState(prevState => ({ ...prevState, 'evidencias': state.evidencias }));
  }

  const removeEvidencia = (idx) => {
    if (state.evidencias.length > 1) {
      state.evidencias.splice(idx, 1);
      setState(prevState => ({ ...prevState, 'evidencias': state.evidencias }));
    }
  }

  const evidencias = () => {
    let value = state.eliminatoria;
    let icon = <CheckIcon className={classes.labelIcon} style={{ fill: value != null && value.toString().trim() == '' ? "#696969" : "#cbd44b" }} />;

    let evidenciasInputs = state.evidencias.map((evidencia, idx, arr) => {
      return React.cloneElement(
        <Grid container item xs={12} lg={12}>
          <Grid container item xs={11} lg={11}>
            <TextField className={classes.inputEvidencia} value={evidencia} variant="outlined"
              onChange={(e) => {handleChangeEvidencia(idx, e)}}
              InputProps={{
                className: classes.input
              }}
            />
          </Grid>
          <Grid container item xs={1} lg={1}>
            <CloseIcon onClick={() => { removeEvidencia(idx) }} className={classes.iconClose} style={{ fill: "#cbd44b", fontSize: '35px' }} />
          </Grid>
        </Grid>,
        { key: idx }
      )
    });

    return <div style={{paddingBottom: 50}}>
      <InputLabel className={classes.label}>
        { icon }
        Quais as evidências objetivas?
      </InputLabel>
      <div style={{ paddingTop: 30}}>
        { evidenciasInputs }
        <Button
          className={classes.btnAddEvidencia}
          variant="contained"
          onClick={addEvidencia}>
          Adicionar
        </Button>
      </div>
    </div>;
  }

  return (
    <div>
      <Header progress="100" title="Configurar Pergunta" backTo="/perguntas" />
      <Grid container item xs={12} lg={12}>
        <Grid container item xs={11} lg={8} className="margin-auto">
          <form className={classes.root} noValidate autoComplete="off">
            <Input name="descricao" label="Qual a pergunta?" setValue={setValue} required={true} value={state.descricao} error={error}/>
            { radio() }
            <Input name="metodo" label="Qual o método de análise?" setValue={setValue} required={true} value={state.metodo} error={error} />
            { evidencias() }
          </form>
          <FormButton text={state.id != 0 ? 'Salvar Alterações' : 'Cadastrar'} clicked={handleSave} />
        </Grid>
      </Grid>
    </div>
  );
}

export default Form;
