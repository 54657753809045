import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ClearDb from './components/common/ClearLocalDb';
import Login from './components/login/Login';
import Home from './components/home/Home';
import ListRelatorio from './components/relatorios/List';
import ListCategoria from './components/categoria/List';
import FormCategoria from './components/categoria/Form';

import ListClasseEq from './components/classe-equipamento/List';
import FormClasseEq from './components/classe-equipamento/Form';

import ListCategoriaPerguntas from './components/pergunta/ListCategoriaPerguntas';
import ListPerguntas from './components/pergunta/List';
import FormPergunta from './components/pergunta/Form';

import InspecCat from './components/relatorio-inspencao/categorias/ListCategoriaPerguntas';
import InspecForm from './components/relatorio-inspencao/pergunta/Form';
import InspecTakePicture from './components/relatorio-inspencao/pergunta/Camera/Camera';
import InspecEditPicture from './components/relatorio-inspencao/pergunta/EditorImagem/EditorImagem';
import InspecConclusao from './components/relatorio-inspencao/conclusao/Conclusao';
import InspecPreview from './components/relatorio-inspencao/preview/Preview';
import InspecEnvio from './components/relatorio-inspencao/envio/Envio';

import InspecaoInicioStep1 from './components/relatorio-inspencao/inicio/Inicio1';
import InspecaoInicioStep2 from './components/relatorio-inspencao/inicio/Inicio2';
import InspecaoInicioStep3 from './components/relatorio-inspencao/inicio/Inicio3';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';

function PrivateRoute ({ component: Component, ...rest }) {
  var item = localStorage.getItem('6405f68e-203b-11eb-adc1-0242ac120002');
  var authed = item != null && item === '702d5348-78bf-41e3-a7b0-86ad9f03dd45';

  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
    />
  )
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={Login}/>
      <Route path="/clear-db" component={ClearDb} />
      
      <PrivateRoute path="/home" component={Home} />

      <PrivateRoute path="/configurar-categorias" component={ListCategoria}/>
      <PrivateRoute path="/configurar-categorias-create" component={FormCategoria}/>
      <PrivateRoute path="/configurar-categorias-edit/:id" component={FormCategoria}/>

      <PrivateRoute path="/classes-equipamentos" component={ListClasseEq}/>
      <PrivateRoute path="/classes-equipamentos-create" component={FormClasseEq}/>
      <PrivateRoute path="/classes-equipamentos-edit/:id" component={FormClasseEq}/>

      <PrivateRoute path="/perguntas" component={ListCategoriaPerguntas}/>
      <PrivateRoute path="/listar-perguntas/:categoriaid" component={ListPerguntas}/>
      <PrivateRoute path="/perguntas-create/:categoriaid" component={FormPergunta}/>
      <PrivateRoute path="/perguntas-edit/:categoriaid/:id" component={FormPergunta}/>

      <PrivateRoute path="/inspecao/iniciar/step1/:id?" component={InspecaoInicioStep1}/>
      <PrivateRoute path="/inspecao/iniciar/step2/:id?" component={InspecaoInicioStep2}/>
      <PrivateRoute path="/inspecao/iniciar/step3/:id?" component={InspecaoInicioStep3}/>

      <PrivateRoute path="/inspecao/pergunta/:relatorioid/:categoriaid/:perguntaid" component={InspecForm} />
      <PrivateRoute path="/inspecao/take-picture/:relatorioid/:categoriaid/:perguntaid" component={InspecTakePicture} />
      <PrivateRoute path="/inspecao/edit-picture/:relatorioid/:categoriaid/:perguntaid/:fotoid" component={InspecEditPicture} />

      <PrivateRoute path="/inspecao/conclusao/:id" component={InspecConclusao} />
      <PrivateRoute path="/inspecao/preview/:id" component={InspecPreview} />
      <PrivateRoute path="/inspecao/enviar-relatorio/:id" component={InspecEnvio} />

      <PrivateRoute path="/relatorios" component={ListRelatorio}/>
      <PrivateRoute path="/inspecao/:id" component={InspecCat} />

    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
