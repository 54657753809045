import React, { Component } from 'react';
import '../../common/Main.css';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Header from '../../common/Header';
import Input from '../../common/Input';
import FormButton from '../../common/FormButton';
import InputLabel from '@material-ui/core/InputLabel';
import { Grid } from '@material-ui/core';

import { defaultValue } from '../../../helpers';

import RelatorioService from '../../../services/relatorioService';
import AprovadoresService from "../../../services/aprovadoresService";
import ClasseEquipService from "../../../services/classeEquipService";

const styles = theme => ({
  root: {
    '& > *': {
      width: '100%',
    },
    width: '100%',
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  labelIcon: {
    padding: 3,
    marginRight: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
    fill: "#cbd44b",
    fontSize: 18,
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
  },
  textField: {
    paddingTop: 10,
    width: '100%',
  },
  checkBox: {
    position: 'relative',
    textAlign: 'left',
    paddingTop: 30,
  },
  checkBoxLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  radioChecked: {
    color: '#cbd44b',
    '&$checked': {
      color: '#cbd44b',
    },
  },
  radioNotChecked: {
    color: '#c9c9c9',
    '&$checked': {
      color: '#c9c9c9',
    },
  },
  input: {
    color: "#cbd44b"
  },
  iconClose: {
    margin: 5,
    marginLeft: 'auto',
    height: 30,
    width: 30,
    padding: 5,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
  }
});

let service = new RelatorioService();
let aprovadoresService = new AprovadoresService();
let classesService = new ClasseEquipService();

class Form extends Component {
  id = null;
  constructor(props) {
    super(props);
    var model;
    if (this.props.match.params.id && this.props.match.params.id != null) {
      this.id = this.props.match.params.id;
      model = service.findById(this.props.match.params.id);
    } else {
      model = service.getTemp();
    }
    this.state = {
      id: 0,
      solicitacao: defaultValue(model, 'solicitacao'),
      tipoinspec: defaultValue(model, 'tipoinspec'),
      tipoelab: defaultValue(model, 'tipoelab'),
      classe: model != null ? model.classe.id : '',
      aprovador: model != null ? model.aprovador.id : '',
      inspetor: defaultValue(model, 'inspetor', JSON.parse(localStorage.getItem('apprat_userinfo')).displayName),

      error: false,

      aprovadores: aprovadoresService.loadAprovadores().filter((a) => { return a.nome != "" && a.email != "" }).map((m) => { return { id: m.email, descricao: m.nome } }),
      classes: classesService.load().map((m) => { return { id: m.id, descricao: m.nome } }),
    }
  }

  validate = () => { 
    return this.state.solicitacao != "" &&
      this.state.tipoinspec != "" &&
      this.state.tipoelab != "" &&
      this.state.classe != "" &&
      this.state.aprovador != "" &&
      this.state.inspetor != '';
  };

  setValue = (name, value) => {
    this.setState({[name]: value });
  }

  handleSave = () => {

    if (this.validate()) {

      this.setState(prevState => ({ ...prevState, 'error': false }))
      var url = '/inspecao/iniciar/step2';
      if (this.id == null) { 
        service.createTemp(this.state,
          this.state.classes.filter(m => m.id == this.state.classe)[0],
          this.state.aprovadores.filter(m => m.id == this.state.aprovador)[0]);
      } else {
        service.updateStep1(
          this.id,
          this.state,
          this.state.classes.filter(m => m.id == this.state.classe)[0],
          this.state.aprovadores.filter(m => m.id == this.state.aprovador)[0]
        );
        url += `/${this.id}`;
      }
      this.props.history.push({
        pathname: url,
        state: this.state
      });

    } else {
      this.setState(prevState => ({ ...prevState, 'error': true }))
    }
    
  }

  comboBox = (titulo, name, number, itens, classes) => {
    let icon = <span className={classes.labelIcon} style={{fill: '#696969'}}>{number}</span>;

    return <div>
      <InputLabel className={classes.label}>
        {icon} {titulo}
      </InputLabel>
      <br/>
      <FormControl variant="outlined" className={classes.formControl} style={{minWidth: '100%'}}>
        <InputLabel>Selecione</InputLabel>
        <Select
          value={this.state[name]}
          onChange={e => this.setState({[name]: e.target.value})}
          label="Selecione"
        >
          {itens.map(e => <MenuItem value={e.id}>{e.descricao}</MenuItem>)}
        </Select>
      </FormControl>
    </div>;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header progress="30" title="Cadastro de Relatório" subtitle="Parte 1/3 - Dados Gerais" backTo="/home"/>
        <Grid container item xs={12} lg={12}>
          <Grid container item xs={11} lg={8} className="margin-auto">
            <form className={classes.root} noValidate autoComplete="off">
              {this.comboBox('Qual o tipo de relatório a ser elaborado?', 'tipoelab', '01', [
                { id: 'RAT - Relatorio de Avaliação Técnica', descricao: 'RAT - Relatorio de Avaliação Técnica' },
                { id: 'RQA - Relatório de Qualidade Assegurada', descricao: 'RQA - Relatório de Qualidade Assegurada' },
                { id: 'Auditoria', descricao: 'Auditoria' }
              ], classes)}
              <Input number={'02'} name="solicitacao" label="Qual a origem da solicitação?" setValue={this.setValue} value={this.state.solicitacao} required={true} error={this.state.error} />
              <Input number={'03'} name="tipoinspec" label="Qual o tipo de material/equipamento a ser inspecionado?" setValue={this.setValue} value={this.state.tipoinspec} required={true} error={this.state.error} />
              {this.comboBox('Qual a classe do material/equipamento?', 'classe', '04', this.state.classes, classes)}
              <Input number={'05'} name="inspetor" label="Inspetor" setValue={this.setValue} value={this.state.inspetor} required={true} error={this.state.error}  />
              {this.comboBox('Selecione o aprovador', 'aprovador', '06', this.state.aprovadores, classes)}
            </form>
            <FormButton text={ 'Próximo' } clicked={this.handleSave} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Form);
