import React, { Component } from 'react'
import './Main.css';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import CheckIcon from '@material-ui/icons/Check';


const styles = theme => ({
  label: {
    position: 'relative',
    textAlign: 'left',
  },
  labelIcon: {
    padding: 3,
    marginRight: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
    fontSize: 18,
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
  },
  textField: {
    paddingTop: 10,
    width: '100%',
  },
  root: {
    paddingTop: 30,
  },
  input: {
    color: "#cbd44b"
  }
});

class Input extends Component {

  constructor(props) {
    super(props);


    //bind necessário para acessar o state
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = event => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    let type = this.props.type;
    if (type !== 'undefined' && type !== null && type === "number") {
      value = value.replace(/\D/g, '');
    }

    this.setState({
      [name]: value,
    });
    this.props.setValue(name, value);
  };

  render() {

    let value = this.props.value;
    let required = this.props.required;
    const {classes} = this.props;
    let icon = "";
    if (required !== 'undefined' && required !== null && required) {
      icon = <CheckIcon className={classes.labelIcon} style={{fill: value.trim() === '' ? "#696969" : "#cbd44b",}}/>;
    }

    return (
      <div className={classes.root}>
        <InputLabel className={classes.label}>
          {
            !this.props.number ?
              icon
              :
              <span className={classes.labelIcon} style={{fill: value.trim() == '' ? "#696969" : "#cbd44b" }}>{this.props.number}</span>
          }
          {this.props.label}
        </InputLabel>
        <TextField
          className={classes.textField}
          name={this.props.name}
          id="standard-basic"
          value={value}
          onChange={this.handleChange}
          required={this.props.required}
          error={this.props.error}
          helperText={this.props.required && this.props.error && value != null &&  value.toString().trim() == '' ? 'Campo obrigatório' : ' '}
          InputProps={{
            className: classes.input,
            type:this.props.type
          }}
        />
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(Input);
