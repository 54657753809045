import React from 'react';
import '../common/Main.css';
import Header from '../common/Header';
import Listing from '../common/Listing';
import CategoriaService from '../../services/categoriaService';

function List() {

  let service = new CategoriaService()

  return (
    <div className="App">
      <Header progress="100" title="Listar Categorias" backTo="/home"/>
      <Listing service={service} listText="nome" route="configurar-categorias"/>
    </div>
  );
}

export default List;
