import { UserAgentApplication } from "msal";

const config = {
    appId: process.env.REACT_APP_AZ_APP_ID,
    redirectUri: process.env.REACT_APP_AZ_REDIRECT_URI,
    scopes: [
        "user.read",
        "Mail.Send",
        "Mail.ReadWrite"
    ]
}
export const userAgentApplication = new UserAgentApplication({
    auth: {
        clientId: config.appId,
        redirectUri: config.redirectUri,
        authority: process.env.REACT_APP_AZ_AUTHORITY,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
});

export const getAccessToken = async () => {
    return userAgentApplication.acquireTokenSilent({
        scopes: config.scopes
    });
}

export const getLoginPopup = () => {
    return userAgentApplication.loginRedirect({
        scopes: config.scopes
    });
}