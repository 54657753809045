
import BaseService  from './baseService.js';

export default class CategoriaService extends BaseService {

    constructor() {
        super('configurar-categorias');
    }

    findById(id) {
        return this.findByIdDb(this.DbCategorias, id);
    }

    create (model) {
        return this.axiosCreate(
            'categoria',
            { _id: null, descricao: model.nome }, model,
            this.DbCategorias
        );
    }

    update (model) {
        return this.axiosUpdate(
            'categoria',
            { _id: model.id, descricao: model.nome }, model,
            this.DbCategorias
        );

    }

    delete (id) {
        return this.axiosDelete(
            `categoria/${id}`, 
            id, 
            this.DbCategorias)
        .then((response) => { 
            //remover perguntas
            return Promise.resolve(true);
        })
        .catch((error) => Promise.reject(error));
    }

    async listAll (parametros = null) {
        return Promise.resolve(this.listAllActive(this.DbCategorias));
    }
    
    listAllCategorias () {
        return this.getDb(this.DbCategorias)
    }
}
