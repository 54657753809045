import React from 'react';
import '../common/Main.css';
import Header from '../common/Header';
import CategoriaPerguntasListing from './CategoriaPerguntasListing';

function List() {
  return (
    <div className="App">
      <Header progress="100" title="Listar Perguntas" backTo="/home"/>
      <CategoriaPerguntasListing />
    </div>
  );
}

export default List;
