import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import { Grid, InputLabel, TextField, IconButton } from "@material-ui/core";
import Swal from 'sweetalert2';

import '../../common/Main.css';
import Header from '../../common/Header';
import Alerta from '../../common/Alert';
import FormButton from '../../common/FormButton';
import Img from './../pergunta/Img';

import { Description, Close as CloseIcon } from '@material-ui/icons';
import { ReactComponent as NewFile } from './../../../svg/file-add.svg';
import { ReactComponent as Delete } from './../../../svg/circle-delete.svg';
import { ReactComponent as Edit } from './../../../svg/circle-edit.svg';
import { ReactComponent as Save } from './../../../svg/circle-check.svg';

import RelatorioService from '../../../services/relatorioService.js';
let relatorioService = new RelatorioService();


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '100%',
    },
    width: '100%',
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  subtitle: {
    marginBottom: '15px',
    fontSize: '1.2em',
    fontWeight: '600'
  },
}));


function Conclusao() {
  const { id } = useParams();
  const classes = useStyles();
  let history = useHistory();

  const [state, setState] = useState({
    valid: false,
    relatorio: null,
    dtRelatorio: '',
    comentarios: '',
    codigosHomologados: [],
    anexos: [],
    anexoEditing: ''
  });

  const [loadedState, loadedSetState] = useState(false);
  const [anexoName, setAnexoName] = useState('');

  if (!loadedState) {
    loadedSetState(true);
    var relatorio = relatorioService.findById(id);
    var anexos = [];
    relatorio.categorias.forEach(cat => {
      cat.perguntas.forEach(p => {
        p.anexos.forEach(a => {
          anexos.push({
            catId: cat.id,
            perId: p.id,
            key: a
          })
        });
      })
    });
    
    relatorio.anexos.forEach(a => { 
      anexos.push({
        catId: '',
        perId: '',
        key: a
      })
    });

    var dt = '';
    if (relatorio.dtRelatorio == '') {
      var now = new Date();
      dt = 
        now.getUTCFullYear() + "-" +
        (now.getUTCMonth() + 1).toString().padStart(2, '0') + "-" + 
        now.getUTCDate().toString().padStart(2, '0');
    } else {
      dt = relatorio.dtRelatorio;
    }

    setState(prevState => ({ ...prevState, 'relatorio': relatorio }));
    setState(prevState => ({ ...prevState, 'anexos': anexos }));
    setState(prevState => ({ ...prevState, 'dtRelatorio': dt }));
    setState(prevState => ({ ...prevState, 'comentarios': relatorio.comentarios }));
    setState(prevState => ({ ...prevState, 'codigosHomologados': relatorio.codigosHomologados.map(m => m.key) }));
  }

  const setValue = (name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  const divDtRelatorio = () => { 
    if (state.relatorio == null) {
      return null;
    }
    return (
      <div>
        <div>
          <InputLabel>
            <span style={{ fontWeight: '600' }}>Data do Relatório</span>
          </InputLabel>
        </div>
        <div>
          <TextField
            type="date"
            style={{width: '150px'}}
            defaultValue={state.dtRelatorio}
            onChange={(evt) => { setValue('dtRelatorio', evt.target.value); }}
          />
        </div>
      </div>
    )
  }
  const divObservacao = () => { 
    if (state.relatorio == null) {
      return null;
    }
    return (
      <div style={{ marginTop: '30px' }}>
        <InputLabel className={classes.subtitle}>
          Comentários:
        </InputLabel>
        <TextField
          style={{ width: '100%' }}
          multiline
          rows={5}
          variant="outlined"
          defaultValue={state.comentarios}
          onChange={(evt) => { setValue('comentarios', evt.target.value); }}
        />
      </div>
    );
  }

  const proccessFile = async (file) => {
    var reader = new FileReader()
    reader.onload = base64 => {
      relatorioService.salvarTabelaCodigosHomologados(state.relatorio, base64.currentTarget.result, (key, fotos) => {
        setState(prevState => ({ ...prevState, 'codigosHomologados': fotos.map(m => m.key) }));
        return Promise.resolve(true);
      });
    }
    reader.readAsDataURL(file);
  }

  const selectTblCodigosHomologados = async (files) => {
    if (files.length > 0) {
      var hasNaoPermitido = false;
      for (var i = 0; i < files.length; i++) {
        var f = files[i];
        if (!f.name.toUpperCase().endsWith(".PNG") && !f.name.toUpperCase().endsWith(".JPG") && !f.name.toUpperCase().endsWith(".JPEG")) {
          hasNaoPermitido = true;
        }
      }
      
      if (hasNaoPermitido) {
        ReactDOM.render(<Alerta severity={'error'} message={'Apenas imagens são aceitas (.png e .jpg)'} />, document.querySelector('#snack_root'));
        return;
      }
      
      for (var i = 0; i < files.length; i++) {
        await proccessFile(files[i]);
      }
    }
  }
  const excluirTblCodigosHomologados = (idx) => {
    Swal.fire({
      title: '',
      text: 'Deseja excluir a Tabela de Códigos Homologados?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        relatorioService.excluirTabelaCodigosHomologados(state.relatorio, state.codigosHomologados[idx], (fotos) => { 
          setState(prevState => ({ ...prevState, 'codigosHomologados': fotos.map(m => m.key) }));
        });
        
      }
    })
  }
  const tabelaCodigosHomologados = () => { 
    if (state.relatorio == null) {
      return null;
    }
    return (
      <div style={{ marginTop: '30px' }}>
        <InputLabel className={classes.subtitle}>
          Tabelas de Códigos Homologados (Imagens)
        </InputLabel>
        <input type="file" style={{ display: 'none' }} id="inputFileTbl" onChange={(evt) => { selectTblCodigosHomologados(evt.target.files) }}
          accept="image/png, image/jpeg" multiple={true} />
        <div style={{ marginRight: '10px', padding: '2px', display: 'inline-block' }}>
          <NewFile onClick={() => { document.getElementById("inputFileTbl").click() }}></NewFile>
        </div>
        {
          state.codigosHomologados.length > 0 
            ? (
              state.codigosHomologados.map((f, idx) => { 
                return React.cloneElement((
                  <div style={{ marginRight: '10px', padding: '2px', border: '1px #e0e0e0 dashed', display: 'inline-block', textAlign: 'center' }}>

                    <Img fotoKey={f}></Img>
                    <div style={{ textAlign: 'center' }}>
                      <IconButton edge="end" onClick={() => { excluirTblCodigosHomologados(idx) }} style={{ marginRight: '2px', marginLeft: '2px' }}>
                        <CloseIcon className={classes.icon} />
                      </IconButton>
                    </div>
                  </div>
                
                ), {
                  key: f
                })
              })
              
              
            ) : (
              null
            )
        }
      </div>
    );
  }

  const processAnexo = (file) => {
    var reader = new FileReader()
    reader.onload = base64 => {
      relatorioService.salvarAnexoRelatorio(state.relatorio, file.name, base64.currentTarget.result, (key) => {
        state.anexos.push({
          key: key,
          catId: '',
          perId: ''
        })
        setState(prevState => ({ ...prevState, 'anexos': state.anexos }));
        return Promise.resolve(true);
      });
    }
    reader.readAsDataURL(file);
  }

  const selectAnexo = async (files) => {
    if (files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        await processAnexo(files[i]);
      }
    }
  }

  const deleteAnexo = (idx) => {
  
    Swal.fire({
      title: '',
      text: 'Deseja excluir o anexo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        var anexo = state.anexos[idx];
        if (anexo.perId != '') {
          relatorioService.deleteAnexo(state.relatorio, anexo.catId, anexo.perId, state.anexos[idx].key);
        } else {
          relatorioService.deleteAnexoRelatorio(state.relatorio, state.anexos[idx].key);
        }
        state.anexos.splice(idx, 1);
        setState(prevState => ({ ...prevState, 'anexos': state.anexos }));
      }
    })
  }

  const editAnexo = (fileKey) => {
    setState(prevState => ({ ...prevState, ['anexoEditing']: fileKey }));
    setAnexoName(relatorioService.getFileName(fileKey))
  }

  const saveAnexo = (fileKey) => {
    relatorioService.saveFileName(fileKey, anexoName);
    setState(prevState => ({ ...prevState, ['anexoEditing']: '' }));
    setAnexoName('')
  }

  const getAnexo = (index, anexo) => {
    return (
      <div style={{ 'marginBottom': '10px', 'marginTop': '10px' }}>
        <Description color="action"></Description>
        <span style={{ "marginLeft": "20px" }}>
          {
            state.anexoEditing == anexo.key
              ?
              (
                <TextField
                  style={{ width: 'calc(100% - 170px)' }}
                  variant="outlined"
                  onChange={(event) => { setAnexoName(event.target.value) }}
                  defaultValue={relatorioService.getFileName(anexo.key)}
                />
              )
              : relatorioService.getFileName(anexo.key)
          }
        </span>
        <span style={{ "float": "right" }}>
          {
            state.anexoEditing != anexo.key ?
              (
                <span>
                  <Edit onClick={() => editAnexo(anexo.key)} style={{ marginRight: '3px' }}></Edit>
                </span>
              ) :
              null
          }
          {
            state.anexoEditing == anexo.key ?
              (
                <span>
                  <Save onClick={() => saveAnexo(anexo.key)} style={{ marginRight: '3px' }}></Save>
                </span>
              ) : null
          }
          <Delete onClick={() => deleteAnexo(index)}></Delete>
        </span>
        <div style={{ clear: 'both' }}></div>
      </div>
    );
  }

  const divAnexos = () => { 
    if (state.relatorio == null) {
      return null;
    }
    return (
      <div style={{ marginTop: '30px' }}>
        <div>
          <input type="file" style={{ display: 'none' }} id="inputFileAnexo" onChange={(evt) => { selectAnexo(evt.target.files) }} multiple={true} />
          <InputLabel className={classes.subtitle}>
            Anexos
          </InputLabel>
          <div>
            <NewFile onClick={() => { document.getElementById("inputFileAnexo").click() }}></NewFile>
          </div>
        </div>
        <div style={{ marginTop: '15px' }}>
          {
            state.anexos.map((a, i, arr) => {
              return React.cloneElement(getAnexo(i, a), {
                key: `divAnexo${a.key}`
              })
            })
          }
        </div>
      </div>
    );
  }

  const preview = () => {
    relatorioService.saveConclusao(state.relatorio, state.dtRelatorio, state.comentarios);
    history.push({
      pathname: `/inspecao/preview/${id}`
    });
  }

  return (
    <div>
      <Header progress="75" title="Conclusão" backTo={`/inspecao/${id}`}/>
      <Grid container item xs={12} lg={12}>
        <Grid container item xs={11} lg={8} className="margin-auto">
          <form className={classes.root}>
            { divDtRelatorio() }
            { divObservacao() }
            { tabelaCodigosHomologados() }
            { divAnexos() }
          </form>
          {
            (state.dtRelatorio != '' &&
              state.dtRelatorio != null &&
              state.comentarios != '') ? 
              (<FormButton text={'Preview do Relatório'} clicked={() => preview()} />) : 
              null
          }
          
        </Grid>
      </Grid>
    </div>
  );
}

export default Conclusao;