import React, { Component } from 'react'
import '../../common/Main.css';
import { withStyles } from '@material-ui/core/styles';
import Header from '../../common/Header';
import FormButton from '../../common/FormButton';
import RelatorioService from '../../../services/relatorioService.js';
import InputLabel from '@material-ui/core/InputLabel';
import { Grid } from '@material-ui/core';
import { AttachFile, Description, PictureAsPdf, Theaters } from '@material-ui/icons';
import { ReactComponent as Delete } from './../../../svg/circle-delete.svg';

import { DropzoneArea } from 'material-ui-dropzone'

let service = new RelatorioService();
let file;

const styles = theme => ({
  root: {
    '& > *': {
      width: '100%',
    },
    width: '100%',
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  labelIcon: {
    padding: 3,
    marginRight: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
    fill: "#cbd44b",
    fontSize: 18,
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
  },
  textField: {
    paddingTop: 10,
    width: '100%',
  },
  checkBox: {
    position: 'relative',
    textAlign: 'left',
    paddingTop: 30,
  },
  checkBoxLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  radioChecked: {
    color: '#cbd44b',
    '&$checked': {
      color: '#cbd44b',
    },
  },
  radioNotChecked: {
    color: '#c9c9c9',
    '&$checked': {
      color: '#c9c9c9',
    },
  },
  input: {
    color: "#cbd44b"
  },
  iconClose: {
    margin: 5,
    marginLeft: 'auto',
    height: 30,
    width: 30,
    padding: 5,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
  }
});


const handlePreviewIcon = (fileObject, classes) => {
  const {type} = fileObject.file
  const iconProps = {
    className: classes.image,
  }

  if (type.startsWith("video/")) return <Theaters {...iconProps} />

  switch (type) {
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return <Description {...iconProps} />
    case "application/pdf":
      return <PictureAsPdf {...iconProps} />
    default:
      return <AttachFile {...iconProps} />
  }
}

const getFileKey = (model) => {
  if (model != null && model.file_apr && model.file_apr != null && model.file_apr != '') {
    return model.file_apr;
  }
  return null;
}

class DropzoneAreaSinglePdf extends Component {
  handleChange(files) {
    this.props.setFiles(files);
    if (files.length > 0) {
      this.props.showButton();
    }
  }

  render() {
    return (
      <DropzoneArea
        dropzoneClass={'dropzone'}
        Icon={PictureAsPdf}
        dropzoneText={"Para importar, clique aqui, ou arraste e solte o documento de Análise Preliminar de Risco."}
        getPreviewIcon={handlePreviewIcon}
        onChange={this.handleChange.bind(this)}
        acceptedFiles={['.pdf']}
        filesLimit={1}
        maxFileSize={15000000}
      />
    )
  }
}

class PdfUploaded extends Component {

  render () {
    return (
      <div style={{ 'marginBottom': '45px', 'marginTop': '45px' }}>
        <Description color="action" fontSize="large"></Description> 
        <span style={{ "marginLeft": "20px" }}>{ this.props.fileName }</span>
        <span style={{ "float": "right" }}>
          <Delete onClick={() => this.props.delete()}></Delete>
        </span>
      </div>
    )
  }
}

class Form extends Component {
  id = null;

  constructor(props) {
    super(props);
    var model;    
    if (this.props.match.params.id && this.props.match.params.id != null) {
      this.id = this.props.match.params.id;
      model = service.findById(this.props.match.params.id);
    } else {
      model = service.getTemp();
    }

    var fileKey = getFileKey(model);
    this.state = {
      showbtn: fileKey != null,
      fileKey: fileKey,
      file: fileKey != null ? '_has_file_' : '',
      fileName: 
        fileKey != null ? 
        service.getFileName(fileKey) : 
        '',
      id: this.id,
      ...this.props.location.state
    }
  }

  handleSave = () => {
    if (this.id == null) {
      service.createRelatorio(this.state.file, this.state.fileName).then((response) => {
        this.props.history.push(`/inspecao/${response}`)
      });
    } else {
      service.updateStep3(this.id, this.state.fileKey, this.state.file, this.state.fileName);
      this.props.history.push(`/inspecao/${this.id}`)
    }
  }

  showButton = () => {
    this.setState({showbtn: true});
  }

  setFiles = (files) => {
    if (files.length > 0) {
      file = files[0];
      var reader = new FileReader()
      reader.onload = base64 => {
        this.setState({ file: base64.currentTarget.result }, () => { });
        this.setState({ fileName: file.name }, () => { });
        this.setState({ fileKey: '' }, () => { });
      }
      reader.readAsDataURL(file);
    }
  }

  deleteFile = () => { 
    this.setState({ file: '' }, () => { });
    this.setState({ fileName: '' }, () => { });
    this.setState({ showbtn: false });
  }

  getBackUrl() {
    var url = '/inspecao/iniciar/step2';
    if (this.id != null) { url += `/${this.id}` }
    return url;
  }

  render() {
    const {classes} = this.props;
    return (
      <div>
        <Header progress="80" title="Cadastro de Relatório" subtitle="Parte 3/3 - APR" backTo={this.getBackUrl()} />
        <Grid container item xs={12} lg={12}>
          <Grid container item xs={11} lg={8} className="margin-auto">
            <form className={classes.root} noValidate autoComplete="off">
              <InputLabel className={classes.label}>
                <span className={classes.labelIcon}>{'01'}</span>
                {'Importar APR'}
              </InputLabel>
              <br />
              {
                this.state.file == '' 
                  ? (<DropzoneAreaSinglePdf showButton={this.showButton} setFiles={this.setFiles} />)
                  : (<PdfUploaded fileName={this.state.fileName} delete={this.deleteFile}></PdfUploaded>)
              }
              
            </form>
            {
              this.state.showbtn ?
                <FormButton text={this.state.id == null ? 'Iniciar Inspeção' : 'Continuar Inspeção'} clicked={this.handleSave} />
                :
                ''
            }
          </Grid>
        </Grid>
      </div>
    );
  }

}

export default withStyles(styles)(Form);
