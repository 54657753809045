import React from 'react';
import './Main.css';
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Swal from 'sweetalert2';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 15,
    paddingRight: 15,
    maginTop: 20,
    backgroundColor: '#F9F9F9',
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  icon: {
    padding: 5,
    margingRight: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
    fill: "#cbd44b",
    fontSize: 20,
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
  },
  divider: {
    minWidth: '100%'
  },
  listItem: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: 'solid 1px #c9c9c9'
  },
  btnAdd: {
    borderRadius: 5,
    backgroundColor: "#009fc2",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingRight: "0px",
    paddingLeft: "0px",
    fontSize: "1.1em",
    width: '150px',
    '&:hover': {
      backgroundColor: '#2ed9ff',
    },
    color: "#ffffff", textTransform: "capitalize",
  },
  iconAdd: {
    paddingRight: 10,
    fill: "#ffffff",
    fontSize: '20px',
    fontWeight: 'bold',
  },
  listHeader: {
    paddingTop: 60,
    textAlign: 'right',
    paddingRight: 30,
    paddingBottom: 30,
  },
  description: {
    maxWidth: 250,
    color: '#808080'
  }
}));

function Listing(props) {

  let history = useHistory();
  const classes = useStyles();

  const [state, setState] = React.useState({
    elements: [],
    loading: true,
  });

  React.useEffect(() => {
    async function getList() {
      await props.service.listAll(props.parametrosList).then((result) => {
        result = result.map((model) => {
          return {id: model.id, listText: model[props.listText]}
        });
        setState({
          elements: result,
          loading: false,
        });
      });
    }

    getList();
  }, []);

  function generateList() {

    if (state.loading) {
      return <CircularProgress mode="indeterminate" color="#fff"/>;
    }

    return state.elements.map((el) =>
      React.cloneElement(
        <div>
          <ListItem className={classes.listItem}>
            <ListItemText className={classes.description}
                          primary={`${el.listText}`}/>
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => {
                edit(el.id)
              }}>
                <CreateIcon className={classes.icon}/>
              </IconButton>
              <IconButton edge="end" onClick={() => {
                del(el.id)
              }}>
                <CloseIcon className={classes.icon}/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </div>
        , {
          key: el.id
        })
    );
  }

  function add() {
    if (props.routeFullCreate != undefined) {
      history.push(`${props.routeFullCreate}`)
    } else {
      history.push(`${props.service.route}-create`)
    }
  }

  function edit(id) {
    if (props.routeEditFull != undefined) {
      history.push(`${props.routeEditFull}/${id}`)
    } else {
      history.push(`${props.service.route}-edit/${id}`)
    }
  }

  function del(id) {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Esse cadastro será excluído!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Excluído',
          'Cadastro excluído!',
          'success'
        );
        props.service.delete(id).then((isDeleted) => {
          if (isDeleted) {
            let elements = state.elements;
            elements.splice(elements.findIndex(item => item.id == id), 1);
            setState({elements: elements});
          }
        });
      }
    })
  }

  const [dense] = React.useState(false);

  return (
    <Grid container item xs={12} lg={12}>
      <Grid item xs={11} lg={8} className="margin-auto">

        <Grid className={classes.listHeader} item xs={12}>
          <Button
            className={classes.btnAdd}
            variant="contained"
            onClick={() => {
              add()
            }}>
            <AddCircleOutlineIcon className={classes.iconAdd}/>
            <span>Adicionar</span>
          </Button>
        </Grid>
        <div className={classes.container}>
          {
            state.elements.length > 0 &&
            <List dense={dense}>
              {generateList()}
            </List>
          }
          {
            state.elements.length == 0 &&
            <div className="text-center" style={{"fontSize": "1.4em", "padding": "30px"}}>Sem itens para exibição</div>
          }

        </div>

      </Grid>

    </Grid>
  );
}

export default Listing;
