
import BaseService  from './baseService.js';
import { getAccessToken } from './azureAd'

export default class AprovadoresService extends BaseService {

    constructor() {
        super('aprovadores');
    }

    loadAprovadores() {
        return this.getDb(this.DbAprovadores);
    }

    save(model) {
        //enviar por api
        return getAccessToken().then((t) => {
            return this.getAxiosClient(10000, t.accessToken)
                .post('aprovadores',
                    model
                        .filter(m => m.nome != '' && m.email != '')
                        .map(m => { return { _id: null, nome: m.nome, email: m.email } })
                )
                .then((response) => {
                    this.replaceDb(this.DbAprovadores, model);
                    return Promise.resolve(true);
                });
        });
        
    }

}