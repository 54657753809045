import React from 'react';
import { useParams } from "react-router-dom";

import '../../common/Main.css';
import Header from '../../common/Header';
import CategoriaPerguntasListing from './CategoriaPerguntasListing';

function List() {
  const { id } = useParams();
  return (
    <div>
      <Header progress="50" title="Inspeção" backTo={`/inspecao/iniciar/step3/${id}`}/>
      <CategoriaPerguntasListing />
    </div>
  );
}

export default List;
