import React from 'react';
import './Main.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  btn: {
    borderRadius: 5,
    backgroundColor: "#009fc2",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "15px",
    paddingLeft: "15px",
    fontSize: "1.2em",
    width: '100%',
    '&:hover': {
      backgroundColor: '#2ed9ff',
    },
    color: "#ffffff", textTransform: "capitalize",
  },
  root: {
    marginBottom: 15,
    marginTop: 20,
    width: '100%',
    //position: 'absolute',
    bottom: 0,
  }
});

function FormButton(props) { 

  const classes = useStyles();
  
  const getLoading = () => {
    return (props.saving != null && props.saving) ? <CircularProgress style={{ 'margin-left': '25px' }} /> : null;
  }
  
  return (
    <div className={classes.root}>
      <Button
        disabled={props.saving != null && props.saving}
        className={classes.btn}
        variant="contained"
        onClick={props.clicked}>
        <span>{ props.text }</span>
        
        { getLoading() }

      </Button>
    </div>
  );
}

export default FormButton;