import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import '../common/Main.css';
import './Login.css';
import Footer from '../common/Footer';
import Alerta from '../common/Alert';
import { Grid, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { userAgentApplication, getAccessToken, getLoginPopup } from '../../services/azureAd';
import { getUserDetails } from "./GraphService";
import ListAllService from '../../services/listAllService';
import CheckOnlineService from '../../services/checkOnlineService';
import CircularProgress from '@material-ui/core/CircularProgress';

function App() {
  let history = useHistory();
  

  const [checkingOnline, setCheckingOnline] = useState(true);
  const doSetCheckingOnline = () => {
    localStorage.removeItem('6405f68e-203b-11eb-adc1-0242ac120002');
    setCheckingOnline(false);
  }


  

  useEffect(() => {

    new CheckOnlineService()
      .checkIsOnline().then(() => {
        //está online - autenticar e carregar dados
        let user = userAgentApplication.getAccount();
        if (user) {
          // Enhance user object with data from Graph
          getUserProfile();
        } else {
          doSetCheckingOnline();
        }
      })
      .catch((e) => {
        //está offline, se tiver autenticado anteriormente, redireciona para a home
        var item = localStorage.getItem('6405f68e-203b-11eb-adc1-0242ac120002');
        if (item != null && item == '702d5348-78bf-41e3-a7b0-86ad9f03dd45') {
          history.push({ pathname: '/home' });
        } else {
          doSetCheckingOnline();
          ReactDOM.render(<Alerta severity={'error'} message={`Não foi possível acessar o servidor e você não estava autenticado anteriormente. Verifique sua conexão de internet e tente novamente.`} />, document.querySelector('#snack_root'));
        }
      })
  }, []);

  const getUserProfile = async () => {
    try {

      var accessToken = await getAccessToken();

      if (accessToken) {
        var user = await getUserDetails(accessToken);
        localStorage.setItem('apprat_userinfo',JSON.stringify(user));
        localStorage.setItem('6405f68e-203b-11eb-adc1-0242ac120002', '702d5348-78bf-41e3-a7b0-86ad9f03dd45');
        var service = new ListAllService();
        service.listAll()
          .then(() => {
            history.push('/home');
          })
          .catch((e) => {
            ReactDOM.render(<Alerta severity={'error'} message={`Erro ao tentar carregar parâmetros: ${e.toString()}.`} />, document.querySelector('#snack_root'));
          });
      }
    } catch (err) {
      console.log(err);
      doSetCheckingOnline();
    }
  };

  var loginAttempt = async () => {
    try {
      getLoginPopup().then(async (r) => {
        await getUserProfile();
      }).catch((e) => { 
        console.log(e)
      });
    } catch (err) {
      console.log(err)
      var error = {};

      if (typeof err === "string") {
        var errParts = err.split("|");
        error =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
        };
      }
    }
  };


  return (
    <div className="App">
      { checkingOnline ? 
      (
        <div style={{textAlign: 'center'}}>
          <CircularProgress style={{ margin: 'auto', marginTop: '80px' }} />
        </div>
      ) : (
        <Grid>
          <Grid className="header" container item xs={12} >
            <img src="./assets/logo.png" className="login-logo" />
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ paddingTop: '130px'}}
          >
            <Grid item xs={12}>
              <Box component="span" display="block" className="text">
                RAT - RELATÓRIO DE AVALIAÇÃO TÉCNICA
              </Box>
            </Grid>   
            <Grid item xs={12} style={{ paddingTop: '60px' }}>
              <Button
                size="lg"
                className="btnEntrar"
                onClick={loginAttempt} variant="contained">
                <img style={{height: '8%', width: '8%', paddingRight: '5px'}} src="./assets/windows-10-icon.png"/>
                <span style={{ color: "#ffffff" }}>Entrar com Azure AD</span>
              </Button>
            </Grid>  
          </Grid> 
        </Grid>
      )}
      
      <Footer/>
    </div>
  );
}

export default App;
