import React from 'react';
import './Main.css';
import { Grid, Box } from '@material-ui/core';

function Footer() { 
    const reload = () => { 
        window.location.reload();
    }
    return (
        <Grid item xs={12} className="footer" style={{ paddingTop: '100px' }}>
            <Box >
                Desenvolvido por <b>Clarify Sistemas</b>
            </Box>
            <Box style={{fontSize: '12px'}}>
                <span style={{ padding: '10px' }} onClick={() => { reload() }}>{process.env.REACT_APP_VERSION} build {process.env.REACT_APP_VERSION_BUILD}</span>
            </Box>
        </Grid> 
    );
}

export default Footer;