import React from 'react';
import { useParams } from "react-router-dom";
import '../../common/Main.css';
import FormButton from '../../common/FormButton';
import { makeStyles } from "@material-ui/core/styles";
import Collapse from '@material-ui/core/Collapse';
import List from "@material-ui/core/List";
import { green } from '@material-ui/core/colors';
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import Grid from "@material-ui/core/Grid";
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import CircularProgress from '@material-ui/core/CircularProgress';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import RelatorioService from '../../../services/relatorioService';
import PerguntaService from '../../../services/perguntaService';
import CategoriaService from '../../../services/categoriaService';


let perguntaService = new PerguntaService();
let categoriaService = new CategoriaService();
let relatorioService = new RelatorioService();

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '100%',
    },
    width: '100%',
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  bottomBtn: {
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: "#009fc2",
    paddingTop: "5px",
    paddingRight: "15px",
    paddingLeft: "15px",
    fontSize: "15px",
    width: '95%',
    '&:hover': {
      backgroundColor: '#2ed9ff',
    },
    color: "#ffffff", textTransform: "capitalize",
  },
  container: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 60,
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  icon: {
    padding: 3,
    fill: "#808080",
    fontSize: 20,
  },
  divider: {
    minWidth: '100%'
  },
  listItem: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: 'solid 1px #f2f2f2'
  },
  lastListItem: {
    paddingTop: 20,
  },
  description: {
  //minWidth: '60%',
    color: '#cbd44b'
  },
  descriptionSublist: {
    //minWidth: '60%',
    color: '#BEBEBE',
    fontWeight: 'bold'
  },
  perguntasLength: {
    color: '#009fc2',
    marginRight: 20,
  },
  btnAdd: {
    borderRadius: 5,
    backgroundColor: "#009fc2",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingRight: "0px",
    paddingLeft: "0px",
    fontSize: "1em",
    width: '10px',
    '&:hover': {
      backgroundColor: '#2ed9ff',
    },
    color: "#ffffff", textTransform: "capitalize",
  },
  iconAdd: {
    fill: "#ffffff",
    fontSize: '20px',
    fontWeight: 'bold',
  },
  icon: {
    padding: 5,
    margingRight: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
    fill: "#cbd44b",
    fontSize: 20,
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
  },
}));

function PerguntaListing(props) {

  const { id } = useParams();
  let history = useHistory();
  const classes = useStyles();

  const foiRespondida = (m) => {
    return m.nota != '' && (m.nota == '5' || m.nota == 'NA' || (m.nota != '5' && m.observacao.toString().trim() != ''));
  }

  const [state, setState] = React.useState({
    categorias: [],
    loading: true,
    openedCategoria: '',
    exibirBtnConclusao: false,
    relatorio: {}
  });
  
  const [dense] = React.useState(false);

  React.useEffect(() => {
    async function getList() {
      let categorias = [];
      let perguntas = [];

      let relatorio = relatorioService.findById(id);

      await categoriaService.listAll().then((result) => {
        categorias = result.map((model) => {
          return {id: model.id, text: model.nome}
        });
      });

      await perguntaService.listAll().then((result) => {
        perguntas = result.map((model) => {
          return { id: model.id, text: model.descricao, categoriaid: model.categoriaid }
        });
      });

      //validar se ja respondeu todas
      var todasRespondidas = true;
      for (var idx1 = 0; idx1 < categorias.length; idx1++) {
        var allPerguntasRespondidas = true;
        var allPerguntas = perguntas.filter(m => m.categoriaid == categorias[idx1].id);
        var perguntasRelatorio = 
          relatorio.categorias.some(m => m.id == categorias[idx1].id)
          ? relatorio.categorias.filter(m => m.id == categorias[idx1].id)[0].perguntas
          : [];
        for (var idx2 = 0; idx2 < allPerguntas.length; idx2++) {
          var p = perguntasRelatorio.filter(m => m.id == allPerguntas[idx2].id);
          if (p.length == 0 || !foiRespondida(p[0])) {
            allPerguntasRespondidas = false;
            break;
          }
        }
        if (!allPerguntasRespondidas) {
          todasRespondidas = false;
          break;
        }
      }
      
      

      setState({
        categorias: categorias,
        perguntas: perguntas,
        loading: false,
        relatorio: relatorio,
        openedCategoria: '',
        exibirBtnConclusao: todasRespondidas
      });
    }
    getList();
  }, []);

  function listPerguntasCategoria (id) {
    if (id == state.openedCategoria) {
      id = '';
    }
    setState(prevState => ({ ...prevState, 'openedCategoria': id }));
  }

  function qtdRespondidas(categoriaId) {
    if (state.relatorio && state.relatorio.categorias) {
      var cats = state.relatorio.categorias.filter(m => m.id == categoriaId);
      if (cats.length > 0) {
        return cats[0].perguntas.filter(m => foiRespondida(m)).length;
      }
    }
    return 0;
  }

  function perguntaFoiRespondida(categoriaId, perguntaId) {
    if (state.relatorio && state.relatorio.categorias) {
      var cats = state.relatorio.categorias.filter(m => m.id == categoriaId);
      if (cats.length > 0) {
        return cats[0].perguntas.some(m => m.id == perguntaId && foiRespondida(m));
      }
    }
    return false;
  }

  function openPergunta (categoriaId, perguntaId) {
    history.push(`/inspecao/pergunta/${state.relatorio.id}/${categoriaId}/${perguntaId}`);
  }

  function generateTarefas(perguntas, categoria) {
    return perguntas.map((pergunta, i, arr) => {
      return React.cloneElement(
        <ListItem button onClick={() => openPergunta(categoria.id, pergunta.id)} className={(arr.length - 1 !== i) ? classes.listItem : classes.lastListItem}>
          <ListItemText className={classes.descriptionSublist}>
            <span style={{ fontWeight: 'bold' }}>{pergunta.text}</span>
          </ListItemText>
          <ListItemSecondaryAction>
            {
              perguntaFoiRespondida(categoria.id, pergunta.id) ? 
              (<CheckCircleIcon fontSize="large" style={{ color: green[500] }}></CheckCircleIcon>) :
              (<RadioButtonUncheckedIcon fontSize="large"></RadioButtonUncheckedIcon>)
            }
          </ListItemSecondaryAction>
        </ListItem>,
        {
          key: pergunta.id
        }
      );
    })
  }

  function generateSublist (categoria, perguntas) {
    return (
      <Collapse in={state.openedCategoria == categoria.id} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            generateTarefas(perguntas, categoria)
          }
        </List>
      </Collapse>
    );
  }

  function generateList() {
    if (state.loading) {
      return <CircularProgress mode="indeterminate"/>;
    }

    if (state.categorias.length > 0) {
      return state.categorias.map((categoria, i, arr) => {

        let perguntas = state.perguntas.filter((p) => {
          return p.categoriaid == categoria.id
        });

        const zeroPad = (num, places) => String(num).padStart(places, '0')

        return React.cloneElement(
          <div>
            <ListItem button onClick={() => listPerguntasCategoria(categoria.id)} className={ (arr.length - 1 !== i) ? classes.listItem : classes.lastListItem }>
              <ListItemText className={classes.description}
                primary={`${categoria.text}`} />
              <span className={classes.perguntasLength}>
                { zeroPad(qtdRespondidas(categoria.id), 2) }/{ zeroPad(perguntas.length, 2) }
              </span>
              <ListItemSecondaryAction>
                {
                  state.openedCategoria == categoria.id ?
                    (<ArrowDownwardIcon style={{ padding: 6 }} />) :
                    (<ArrowForwardIcon style = {{ padding: 6 }} />)
                }
              </ListItemSecondaryAction>
            </ListItem>
            {generateSublist(categoria, perguntas) }
          </div>
          , {
          key: categoria.id
        })
      });
    } else {
      return <div className="text-center" style={{ "fontSize": "1.4em", "padding": "30px" }}>
        Sem itens para exibição
      </div>;
    }
  }

  function irConclusao() {
    history.push(`/inspecao/conclusao/${state.relatorio.id}`);
  }

  return (
    <div>
      <Grid container item xs={12} lg={12}>
        <Grid container item xs={11} lg={8} className="margin-auto">
          <div className={classes.root}>
            <List dense={dense}>
              <Card>
                <CardContent>
                { generateList() }
                </CardContent>
              </Card>
            </List>
            {
              state.exibirBtnConclusao ? 
                (<FormButton text={'Ir Para Conclusão'} clicked={() => irConclusao()} /> )
                : null
            }
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default PerguntaListing;
