import React from 'react';
import '../common/Main.css';
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CreateIcon from '@material-ui/icons/Create';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import PerguntaService from '../../services/perguntaService';
import CategoriaService from '../../services/categoriaService';

let perguntaService = new PerguntaService();
let categoriaService = new CategoriaService();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  bottomBtn: {
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: "#009fc2",
    paddingTop: "5px",
    paddingRight: "15px",
    paddingLeft: "15px",
    fontSize: "15px",
    width: '95%',
    '&:hover': {
      backgroundColor: '#2ed9ff',
    },
    color: "#ffffff", textTransform: "capitalize",
  },
  container: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 60,
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  icon: {
    padding: 3,
    fill: "#808080",
    fontSize: 20,
  },
  divider: {
    minWidth: '100%'
  },
  listItem: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: 'solid 1px #f2f2f2'
  },
  lastListItem: {
    paddingTop: 20,
  },
  description: {
  //minWidth: '60%', 
    color: '#cbd44b'
  },
  perguntasLength: {
    color: '#009fc2',
    marginRight: 20,
  },
  btnAdd: {
    borderRadius: 5,
    backgroundColor: "#009fc2",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingRight: "0px",
    paddingLeft: "0px",
    fontSize: "1em",
    width: '10px',
    '&:hover': {
      backgroundColor: '#2ed9ff',
    },
     color: "#ffffff", textTransform: "capitalize",
  },
  iconAdd: {
    fill: "#ffffff", 
    fontSize: '20px',
    fontWeight: 'bold',
  },
  icon: {
    padding: 5,
    margingRight: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
    fill: "#cbd44b",
    fontSize: 20,
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)',
  },
}));

function PerguntaListing(props) { 

  let history = useHistory();
  const classes = useStyles();
  
  const [state, setState] = React.useState({
    categorias: [],
    loading: true,
  });

  React.useEffect(() => {
    async function getList() {

      let categorias = [];
      let perguntas = [];

      await categoriaService.listAll().then((result) => {
        categorias = result.map((model) => { 
          return {id: model.id, text: model.nome}
        });
      });

      await perguntaService.listAll().then((result) => {
        perguntas = result.map((model) => { 
          return { id: model.id, text: model.descricao, categoriaid: model.categoriaid }
        });
      });

      setState({
        categorias: categorias,
        perguntas: perguntas,
        loading: false,
      });
    }
    getList();
  }, []);


  function generateList() {

    if (state.loading) {
      return <CircularProgress mode="indeterminate"/>;
    }

    if (state.categorias.length > 0) {
      return state.categorias.map((categoria, i, arr) => {

        let perguntas = state.perguntas.filter((p) => {
          return p.categoriaid == categoria.id
        });

        const zeroPad = (num, places) => String(num).padStart(places, '0')

        return React.cloneElement(
          <div>
            <ListItem button onClick={() => listPerguntasCategoria(categoria.id, categoria.text)} className={ (arr.length - 1 !== i) ? classes.listItem : classes.lastListItem }>
              <ListItemText className={classes.description}
                primary={`${categoria.text}`} />
              <span className={classes.perguntasLength}>
                { zeroPad(perguntas.length, 2) }
              </span>
              <ListItemSecondaryAction>
                <ArrowForwardIcon style={{padding: 6}} />
              </ListItemSecondaryAction>
            </ListItem>
          </div>
          , {
          key: categoria.id
        })
      });
    } else {
      return <div className="text-center" style={{ "fontSize": "1.4em", "padding": "30px" }}>
        Sem itens para exibição
      </div>;
    }
  }

  function listPerguntasCategoria(id, title) {
    history.push({
      pathname: '/listar-perguntas/'+id,
      state: { categoriaTitle: title }
    })
  }

  const [dense] = React.useState(false);
  
  return (
    <Grid item xs={12} md={12}>
      <Grid item xs={11} lg={8} className="margin-auto">
        <div className={classes.container}>
          <List dense={dense}>
            <Card>
              <CardContent>
              { generateList() } 
              </CardContent>
            </Card>
          </List>
        </div>
      </Grid>
    </Grid>
  );
}

export default PerguntaListing;