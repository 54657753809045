import React, { Component } from 'react';

import IconButton from "@material-ui/core/IconButton";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import Button from "@material-ui/core/Button";
import Webcam from "react-webcam";

import '../../../common/Main.css';

import RelatorioService from '../../../../services/relatorioService.js';
let relatorioService = new RelatorioService();

class CameraDialog extends Component {
  webcamRef;
  capture;
  relatorioid; 
  categoriaid; 
  perguntaid;

  constructor(props) { 
    super(props);
    this.webcamRef = React.createRef();
    
    this.relatorioid = props.match.params.relatorioid;
    this.categoriaid = props.match.params.categoriaid;
    this.perguntaid = props.match.params.perguntaid;

    this.state = {
      loadingDevices: true,
      hasCamera: false,
      grantedAccess: null
    }
    navigator.mediaDevices.enumerateDevices().then((response) => {
      var hasCam = response != undefined && response.length > 0 && response.some(m => m.kind == 'videoinput');
      this.setState(prevState => ({ ...prevState, 'hasCamera': hasCam }))
      this.setState(prevState => ({ ...prevState, 'loadingDevices': false }))
    });
    
  }

  handleSave() {
    if (this.state.imgTaked != null) {
      relatorioService
        .salvarFotoPergunta(relatorioService.findById(this.relatorioid), this.categoriaid, this.perguntaid, null, this.state.imgTaked, () => {
          this.handleClose()
        });
    }
    
  }
  
  handleClose () {
    this.props.history.push({
      pathname: `/inspecao/pergunta/${this.relatorioid}/${this.categoriaid}/${this.perguntaid}`
    });
  }

  takePhoto() {
    var img = this.webcamRef.current.getScreenshot();
    this.setState(prevState => ({ ...prevState, 'imgTaked': img }))
  }

  onUserMedia (obj) {
    this.setState(prevState => ({ ...prevState, 'grantedAccess': true }))
  }

  onUserMediaError(error) {
    if ((error.name == 'NotAllowedError') || (error.name == 'PermissionDismissedError')) {
      this.setState(prevState => ({ ...prevState, 'grantedAccess': false }))
    }
    
  }

  render () { 
    return (
      <div style={{ height: `100vh`, width: `100vw`, overflow: 'hidden', textAlign: 'center' }}>
      
        {
          !this.state.loadingDevices ? null :
            (
              <h4>Carregando dispositivos...</h4>
            )
        }
        {
          !this.state.loadingDevices && this.state.hasCamera && this.state.grantedAccess == null ? (
            <h4>Aguardando permissão de acesso do usuário</h4>
          ) : null
        }

        {
          !this.state.loadingDevices && this.state.hasCamera && this.state.grantedAccess == false ? (
            <h4>Usuário não permitiu acesso à camera</h4>
          ) : null
        }
        {
          !this.state.loadingDevices && !this.state.hasCamera ? (
            <h4>Nenhuma câmera encontrada</h4>
          ) : null
        }
        <Webcam ref={this.webcamRef}
          onUserMedia={(obj) => { this.onUserMedia(obj) }}
          onUserMediaError={(error) => { this.onUserMediaError(error) }}
          screenshotFormat="image/jpeg"
          videoConstraints={{ facingMode: "environment" }}
          style={{ height: '100%' }}
        />
        
        {
          this.state.grantedAccess != null && this.state.grantedAccess == true ?
            (
              <div style={{ bottom: '35px', position: 'absolute', margin: 'auto', width: '100%', textAlign: 'center' }}>
                <IconButton edge="end" onClick={() => { this.takePhoto() }} variant="contained" color='secondary'>
                  <PhotoCameraIcon fontSize="large" />
                </IconButton>
              </div>
            ) : null
        }
        
        {
          this.state.imgTaked != null ?
            (
              <img src={this.state.imgTaked} style={{ width: '65px', bottom: '10px', position: 'absolute', right: '10px' }} />
            ) : null
        }

        <div style={{ bottom: '10px', position: 'absolute', margin: 'auto', width: '100%', textAlign: 'center' }}>
          {
            this.state.imgTaked == null ?
              null :
              (
                <Button style={{marginRight: '5px', marginRight: '5px'}} onClick={() => { this.handleSave() }} variant="contained" color="primary">
                  Usar Foto
                </Button>
              )
          }

          <Button style={{ marginRight: '5px', marginRight: '5px' }} onClick={() => { this.handleClose() }} variant="contained" color="secondary">
            Cancelar
          </Button>
        
        </div>

        

      </div>
    ); 
  }

}

export default CameraDialog;