
import BaseService  from './baseService.js';

export default class PerguntaService extends BaseService {

    constructor() {
        super('perguntas');
    }

    findById(id) {
        return this.findByIdDb(this.DbPergunta, id);
    }

    create(model) {
        return this.axiosCreate(
            'pergunta',
            {  
                _id: null, 
                descricao: model.descricao, 
                caraterEliminatorio: model.eliminatoria == 'true' ? true : false,
                metodoAnalise: model.metodo,
                evidencias: model.evidencias,
                categoriaId: model.categoriaid
            }, model,
            this.DbPergunta
        );
    }

    update(model) {
        return this.axiosUpdate(
            'pergunta',
            {
                _id: model.id,
                descricao: model.descricao,
                caraterEliminatorio: model.eliminatoria == 'true' ? true : false,
                metodoAnalise: model.metodo,
                evidencias: model.evidencias,
                categoriaId: model.categoriaid
            }, model,
            this.DbPergunta
        );
    }

    delete (id) {
        return this.axiosDelete(
            `pergunta/${id}`,
            id,
            this.DbPergunta
        );
    }

    async listAll(parametros = null) {
        if (parametros == null) {
            return Promise.resolve(
                this.listAllActive(this.DbPergunta)
            );
        } else {
            return this.listAllActive(this.DbPergunta).filter(m => m.categoriaid == parametros.categoriaid)
        }
    }
    
    listAllRelatorio () {
        return this.getDb(this.DbPergunta);
    }
}