import React from 'react';
import { unmountComponentAtNode } from "react-dom";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert (props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        "& .MuiPaper-root": {
            borderRadius: "5px",
            fontWeight: 'bold',
            color: '#FFFFFF !important',
            boxShadow: "2px 2x 5px 0px rgba(0,0,0,0.75);",
        }
    },
}));

export default function CustomizedSnackbars (props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        unmountComponentAtNode(document.getElementById('snack_root'));
    };

    return (
        <div className={classes.root}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={props.severity}>
                    { props.message }
                </Alert>
            </Snackbar>
        </div>
    );
}
