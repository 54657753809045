
import BaseService  from './baseService.js';
import { getAccessToken } from './azureAd'

export default class RelatorioService extends BaseService {

    constructor() {
        super('configurar-relatorios');
    }

    findById(id) {
        return this.findByIdDb(this.DbRelatorio, id);
    }

    getTemp() {
        var temp = localStorage.getItem(this.DbRelatorioTemp);
        if (temp != null && temp != '') {
            let array = JSON.parse(temp);
            if (array.length > 0) {
                return array[0];
            }
        }
        return null;
    }

    createTemp(model, classe, aprovador) {
        localStorage.setItem(this.DbRelatorioTemp, JSON.stringify([]));
        this.insertDb(this.DbRelatorioTemp, { 
            solicitacao: model.solicitacao,
            tipoinspec: model.tipoinspec,
            tipoelab: model.tipoelab,
            classe: classe,
            aprovador: aprovador,
            inspetor: model.inspetor,
        }, false) 
        return Promise.resolve(true);
    }

    updateStep1 (id, model, classe, aprovador) {
        var db = this.findById(id);
        db.solicitacao = model.solicitacao;
        db.tipoinspec = model.tipoinspec;
        db.tipoelab = model.tipoelab;
        db.classe = classe;
        db.aprovador = aprovador;
        db.inspetor = model.inspetor;
        this.updateDb(this.DbRelatorio, db);
    }
    
    setDataStep2(obj, model) {
        obj.nome = model.nome;
        obj.codigo = model.codigo;
        obj.ende = model.ende;
        obj.contato = model.contato;
        obj.fone = model.fone;
        obj.email = model.email;
        obj.especificacao = model.especificacao;
    }
    
    updateTempParte2 (model) {
        var obj = JSON.parse(localStorage.getItem(this.DbRelatorioTemp))[0];
        this.setDataStep2(obj, model);
        localStorage.setItem(this.DbRelatorioTemp, JSON.stringify([obj]))
        return Promise.resolve(true);
    }
    
    updateStep2 (id, model) {
        var db = this.findById(id);
        this.setDataStep2(db, model);
        this.updateDb(this.DbRelatorio, db);
    }

    createRelatorio (arquivo, fileName) {
        var obj = JSON.parse(localStorage.getItem(this.DbRelatorioTemp))[0];
        if (obj.file_apr && obj.file_apr != null && obj.file_apr != '') { 
            this.deleteFile(obj.file_apr);
        }

        var fileKey = this.storeFile(fileName, arquivo);
        obj.file_apr = fileKey;
        obj.categorias = [];

        //tela de conclusao
        obj.dtRelatorio = '';
        obj.comentarios = '';
        obj.codigosHomologados = [];
        obj.anexos = [];

        localStorage.removeItem(this.DbRelatorioTemp);
        var id = this.insertDb(this.DbRelatorio, obj, true);
        localStorage.setItem('apprat_ultimo_relatorio', id);
        return Promise.resolve(id);
    }
    
    updateStep3 (id, key, arquivo, fileName) {
        var db = this.findById(id);
        if (db.file_apr != key) {
            this.deleteFile(db.file_apr);
            var fileKey = this.storeFile(fileName, arquivo);
            db.file_apr = fileKey;
            this.updateDb(this.DbRelatorio, db);
        }
    }

    getFileName(fileKey) {
        return localStorage.getItem(`${this.DbFileNamePrefix}${fileKey}`);
    }
    
    saveFileName (fileKey, name) {
        localStorage.removeItem(`${this.DbFileNamePrefix}${fileKey}`);
        localStorage.setItem(`${this.DbFileNamePrefix}${fileKey}`, name);
    }

    getCategoriaRelatorio(relatorio, categoriaid) {
        var cats = relatorio.categorias.filter(m => { return m.id == categoriaid });
        var categoria = null;
        if (cats.length == 0) {
            categoria = {
                id: categoriaid,
                nome: this.findByIdDb(this.DbCategorias, categoriaid).nome,
                perguntas: []
            };
            relatorio.categorias.push(categoria);
        } else {
            categoria = cats[0];
        }
        return categoria;
    }

    getPerguntaCategoriaRelatorio (categoria, perguntaid) {
        var pergs = categoria.perguntas.filter(m => { return m.id == perguntaid });
        var pergunta = null;
        if (pergs.length == 0) {
            var m = this.findByIdDb(this.DbPergunta, perguntaid);
            pergunta = {
                id: m.id,
                descricao: m.descricao,
                eliminatoria: m.eliminatoria,
                metodo: m.metodo,
                evidencias: m.evidencias,
                nota: '',
                observacao: '',
                fotos: [],
                anexos: []
            };
            categoria.perguntas.push(pergunta);
        } else {
            pergunta = pergs[0];
        }
        return pergunta;
    }

    resizedataURL (datas) {
        return new Promise(async function (resolve, reject) {
            
            // We create an image to receive the Data URI
            var img = document.createElement('img');

            // When the event "onload" is triggered we can resize the image.
            img.onload = () => {
                var wh = { w: img.width, h: img.height};
                if (img.width > 1024 || img.height > 768) { 
                    var oRatio = img.width / img.height;
                    var cRatio = 1024 / 768;
                    var thisWidth;
                    var thisHeight;

                    if (oRatio > cRatio) {
                        thisWidth = 1024;
                        thisHeight = 1024 / oRatio;
                    } else {
                        thisWidth = 768 * oRatio;
                        thisHeight = 768;
                    }
                    wh = { w: thisWidth, h: thisHeight };
                }

                // We create a canvas and get its context.
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');

                // We set the dimensions at the wanted size.
                canvas.width = wh.w;
                canvas.height = wh.h;

                // We resize the image with the canvas method drawImage();
                ctx.drawImage(img, 0, 0, wh.w, wh.h);

                var dataURI = canvas.toDataURL();

                // This is the return of the Promise
                resolve({ img: dataURI, w: wh.w, h: wh.h });
            };

            // We put the Data URI in the image's src attribute
            img.src = datas;

        })
    }

    salvarFotoPergunta (relatorio, categoriaid, perguntaid, fotoKey, fotoBase64, fnc = null) {
        //validar se já tem a categoria cadastrada
        var categoria = this.getCategoriaRelatorio(relatorio, categoriaid);
        var pergunta = this.getPerguntaCategoriaRelatorio(categoria, perguntaid);
        if (fotoKey != null) {
            var f = pergunta.fotos.filter(m => m.key == fotoKey)[0];
            this.resizedataURL(fotoBase64).then((r) => {
                f.width = r.w;
                f.height = r.h;

                //replace
                this.updateFile(fotoKey, r.img, () => {
                    //atualizar o relatorio no banco local
                    this.updateDb(this.DbRelatorio, relatorio);
                    if (fnc != null)
                        fnc(fotoKey, pergunta.fotos);
                });    
            });
        } else {
            //incluir
            this.resizedataURL(fotoBase64).then((r) => {
            
                this.storeFile('fotoTemp', r.img, (key) => {
                    pergunta.fotos.push({
                        key: key,
                        width: r.w,
                        height: r.h
                    });
                    this.updateDb(this.DbRelatorio, relatorio);
                    if (fnc != null)
                        fnc(key, pergunta.fotos);
                });
                
            });
        }
    }

    excluirFotoPergunta (relatorio, categoriaid, perguntaid, fotoKey, endFnc = (fotos) => {}) {
        //validar se já tem a categoria cadastrada
        var categoria = this.getCategoriaRelatorio(relatorio, categoriaid);
        var pergunta = this.getPerguntaCategoriaRelatorio(categoria, perguntaid);
        if (fotoKey != null) {
            //replace
            this.deleteFile(fotoKey, () => {
                pergunta.fotos = pergunta.fotos.filter(m => m.key != fotoKey);
                //atualizar o relatorio no banco local
                this.updateDb(this.DbRelatorio, relatorio);
                endFnc(pergunta.fotos);
            });
        }
        
    }

    deleteAnexo (relatorio, categoriaid, perguntaid, fileKey) {
        //validar se já tem a categoria cadastrada
        var categoria = this.getCategoriaRelatorio(relatorio, categoriaid);
        var pergunta = this.getPerguntaCategoriaRelatorio(categoria, perguntaid);
        if (fileKey != null) {
            //replace
            this.deleteFile(fileKey);
        }
        pergunta.anexos = pergunta.anexos.filter(m => m != fileKey);
        //atualizar o relatorio no banco local
        this.updateDb(this.DbRelatorio, relatorio);
    }

    deleteAnexoRelatorio (relatorio, key) {
        if (key != null) {
            this.deleteFile(key);
        }
        relatorio.anexos = relatorio.anexos.filter(m => m != key);
        //atualizar o relatorio no banco local
        this.updateDb(this.DbRelatorio, relatorio);
    }
    

    salvarNotaObservaocaoPergunta (relatorio, categoriaid, perguntaid, campo, valor) {
        //validar se já tem a categoria cadastrada
        var categoria = this.getCategoriaRelatorio(relatorio, categoriaid);
        var pergunta = this.getPerguntaCategoriaRelatorio(categoria, perguntaid);
        pergunta[campo] = valor;
        //atualizar o relatorio no banco local
        this.updateDb(this.DbRelatorio, relatorio);
    }

    getFoto (key, fnc) {
        this.getFile(key, fnc);
    }

    salvarAnexo (relatorio, categoriaid, perguntaid, fileName, base64file, fnc = null) {
        var categoria = this.getCategoriaRelatorio(relatorio, categoriaid);
        var pergunta = this.getPerguntaCategoriaRelatorio(categoria, perguntaid);
        this.storeFile(fileName, base64file, (anexoKey) => { 
            pergunta.anexos.push(anexoKey);
            //atualizar o relatorio no banco local
            this.updateDb(this.DbRelatorio, relatorio);
            if (fnc != null) {
                fnc(anexoKey, pergunta.anexos)
            }
        });
    }

    salvarAnexoRelatorio (relatorio, fileName, base64file, fnc) {
        this.storeFile(fileName, base64file, (anexoKey) => {
            relatorio.anexos.push(anexoKey);
            //atualizar o relatorio no banco local
            this.updateDb(this.DbRelatorio, relatorio);
            fnc(anexoKey);
        });
    }
    
    gerarRelatorio (relatorio, pdfBase64, fnc) {
        this.storeFile('relatorio_final.pdf', pdfBase64, (relatorioKey) => {
            relatorio.pdf_relatorio = relatorioKey;
            //atualizar o relatorio no banco local
            this.updateDb(this.DbRelatorio, relatorio);
            fnc(relatorioKey);
        });
    }

    salvarTabelaCodigosHomologados (relatorio, fotoBase64, fnc) {
        //incluir
        this.resizedataURL(fotoBase64).then((r) => {

            this.storeFile(`codigoHomologado${relatorio.id}`, r.img, (key) => {
                relatorio.codigosHomologados.push({
                    key: key,
                    width: r.w,
                    height: r.h
                });
                this.updateDb(this.DbRelatorio, relatorio);
                if (fnc != null)
                    fnc(key, relatorio.codigosHomologados);
            });

        });
    }
    
    excluirTabelaCodigosHomologados (relatorio, fotoKey, fnc) {
        this.deleteFile(fotoKey, () => {
            relatorio.codigosHomologados = relatorio.codigosHomologados.filter(m => m.key != fotoKey);
            //atualizar o relatorio no banco local
            this.updateDb(this.DbRelatorio, relatorio);
            fnc(relatorio.codigosHomologados);
        });
    }
    
    saveConclusao (relatorio, dtRelatorio, comentarios) {
        relatorio.dtRelatorio = dtRelatorio;
        relatorio.comentarios = comentarios;
        this.updateDb(this.DbRelatorio, relatorio);
    }

    enviarRelatorioJson (relatorio) {
        return getAccessToken().then((t) => { 
            return this.getAxiosClient(180000, t.accessToken).post(`enviar-relatorio`, relatorio);
        });
        
    }

    async sendFile (file) {
        return getAccessToken().then((t) => { 
            return this.getAxiosClient(180000, t.accessToken).post(`enviar-arquivo`, file);
        });
    }

    dispararEnvioEmail (_id, token) {
        return this.getAxiosClient(180000, token).post(`enviar-email/${_id}`);
    }

    downloadZip (_id, token) {
        return this.getAxiosClient(180000, token, 'blob').get(`download-arquivo/${_id}`, { responseType: 'blob' });
    }

    saveId (relatorio, id) {
        relatorio._id = id;
        this.updateDb(this.DbRelatorio, relatorio);
    }
    
    deleteRelatorio(relatorio) {
        var files = [];
        //excluir anexos
        relatorio.anexos.forEach(f => { files.push(f); });

        //excluir apr
        if (relatorio.file_apr != null && relatorio.file_apr != '') {
            files.push(relatorio.file_apr);
        }

        //excluir codigos
        relatorio.codigosHomologados.forEach(f => { files.push(f.key); });

        //excluir fotos
        relatorio.categorias.forEach(c => { 
            c.perguntas.forEach(p => {
                p.anexos.forEach((a) => {
                    files.push(a);
                });
                p.fotos.forEach((a) => {
                    files.push(a.key);
                });
            })
        });

        files.forEach(element => {
            this.deleteFile(element)
        });

        this.delete(relatorio.id);
    }

    delete(id) {
        var db = this.getDb(this.DbRelatorio);
        db = db.filter(m => m.id != id);
        localStorage.setItem(this.DbRelatorio, JSON.stringify(db));
    }

    async update(model) {
        let id = this.updateDb(this.DbRelatorio, model);
        return Promise.resolve(id);
    }

    async listAll (parametros = null) {
        return Promise.resolve(this.listAllActive(this.DbRelatorio));
    }
}
