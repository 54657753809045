import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { unmountComponentAtNode } from "react-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Alerta from '../common/Alert';

import './Modal.css';
import Input from '../common/Input';
import AprovadoresService from '../../services/aprovadoresService.js';

let service = new AprovadoresService();

export default function FormDialog(aprovadores) {
  let apr = aprovadores.aprovadores;
  const [state, setState] = useState({
    nome1: apr.length > 0 ? apr[0].nome : "",
    email1: apr.length > 0 ? apr[0].email : "",
    nome2: apr.length > 1 ? apr[1].nome : "",
    email2: apr.length > 1 ? apr[1].email : ""
  });

  const [open, setOpen] = useState(true);

  const [error, setError] = useState({list: []});

  const handleClose = () => {
    setOpen(false);
    unmountComponentAtNode(document.getElementById('modal_root'));
  };

  const validadeField = (field, name) => {
    error.list[name] = (field == null || field.trim() === "");
    setError({list: error.list});
  };

  const handleSave = () => {

    validadeField(state.nome1, 'nome1');
    validadeField(state.email1, 'email1');

    if (error.list['nome1'] == false && error.list['email1'] == false) {
      service.save([
        {nome: state.nome1, email: state.email1},
        {nome: state.nome2, email: state.email2}
      ]).then((response) => { 
        handleClose();
        ReactDOM.render(<Alerta severity={'success'} message={'Alterações salva com sucesso.'} />, document.querySelector('#snack_root'));
      }).catch((error) => { 
        ReactDOM.render(<Alerta severity={'error'} message={`Erro ao tentar salvar: ${error.message}`} />, document.querySelector('#snack_root'));
      })
      
    }
  }

  let setValue = (name, value) => {
    setState(prevState => ({...prevState, [name]: value}));
    error.list[name] = false;
    setError({list: error.list});
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className="noBgModal"
      maxWidth={600}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <DialogContent>
        <Card className="card-aprovador">
          <CardContent>
            <label className="aprovador-title">Aprovador 1</label>
            <Input name="nome1" label="Nome" setValue={setValue} value={state.nome1} required={true} error={error.list['nome1']}/>
            <Input name="email1" type="email" label="E-mail" setValue={setValue} value={state.email1} required={true} error={error.list['email1']}/>
          </CardContent>
        </Card>
        <Card className="card-aprovador">
          <CardContent>
            <label className="aprovador-title">Aprovador 2</label>
            <Input name="nome2" label="Nome" setValue={setValue} value={state.nome2}/>
            <Input name="email2" label="E-mail" setValue={setValue} value={state.email2}/>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions style={{justifyContent: "center"}}>
        <Button onClick={handleSave} className="btnAcao">
          Salvar
        </Button>
        <Button onClick={handleClose} className="btnAcao">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
