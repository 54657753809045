import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import '../common/Main.css';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../common/Header';
import Input from '../common/Input';
import FormButton from '../common/FormButton';

import CategoriaService from '../../services/categoriaService.js';
import Alerta from '../common/Alert';
import { Grid } from '@material-ui/core';

let service = new CategoriaService();

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '100%',
    },
    width: '100%',
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
}));

function Form(props) {
  const classes = useStyles();

  const [state, setState] = useState({ nome: "", id: 0, isSaving: false});
  const [loadedState, loadedSetState] = useState(false);
  const [error, setError] = useState(false);
  const {id} = props.match.params;

  if (id != null && id != "undefined" && !loadedState) {
    loadedSetState(true);
    var model = service.findById(id);
    setState(prevState => ({...prevState, 'id': model.id}));
    setState(prevState => ({...prevState, 'nome': model.nome}));
  }

  let setValue = (name, value) => {
    setState(prevState => ({...prevState, [name]: value}));
    setError(false);
  }
  
  const handlePromisse = (promise) => {
    promise
      .then((response) => handleSuccess())
      .catch((error) => {
        setState(prevState => ({ ...prevState, 'isSaving': false }));
        ReactDOM.render(<Alerta severity={'error'} message={`Erro ao tentar salvar: ${error.message}`} />, document.querySelector('#snack_root'));
      });
  }

  const handleSuccess = () => {
    props.history.push("/configurar-categorias");
    setState(prevState => ({ ...prevState, 'isSaving': false }));
    ReactDOM.render(<Alerta severity={'success'} message={'Registro ' + (state.id == 0 ? 'criado' : 'alterado') + ' com sucesso.'} />, document.querySelector('#snack_root'));
  }

  const handleSave = () => {

    if (state.nome != null && state.nome.trim() != "") {
      setState(prevState => ({ ...prevState, 'isSaving': true }));
      if (state.id == 0) {
        handlePromisse(service.create(state));
      } else {
        handlePromisse(service.update(state));
      }
    } else {
      setError(true);
    }
  }

  return (
    <div>
      <Header progress="100" title="Cadastro de Categorias" backTo="/configurar-categorias"/>
      <Grid container item xs={12} lg={12}>
        <Grid container item xs={11} lg={8} className="margin-auto">
          <form className={classes.root} noValidate autoComplete="off">
            <Input name="nome" label="Qual o nome da Categoria?" setValue={setValue} required={true} value={state.nome} error={error} />
          </form>
          <FormButton text={state.id == 0 ? 'Cadastrar' : 'Salvar Alterações'} clicked={handleSave} saving={state.isSaving} />
        </Grid>
      </Grid>
    </div>
  );
}

export default Form;
