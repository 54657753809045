import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ReactDOM from 'react-dom';

import '../../common/Main.css';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../../common/Header';
import Alerta from '../../common/Alert';
import FormButton from '../../common/FormButton';

import { Description, Close as CloseIcon, Create as CreateIcon, Check as CheckIcon } from '@material-ui/icons';
import { Grid, IconButton, InputLabel, TextField } from '@material-ui/core';

import { ReactComponent as Bullet } from './../../../svg/bullet.svg';
import { ReactComponent as NewFile } from './../../../svg/file-add.svg';
import { ReactComponent as Camera } from './../../../svg/camera.svg';
import { ReactComponent as SearchFile } from './../../../svg/search.svg'
import { ReactComponent as Delete } from './../../../svg/circle-delete.svg';
import { ReactComponent as Edit } from './../../../svg/circle-edit.svg';
import { ReactComponent as Save } from './../../../svg/circle-check.svg';

import PerguntaService from '../../../services/perguntaService.js';
import RelatorioService from '../../../services/relatorioService.js';
import CategoriaService from '../../../services/categoriaService.js';

import Swal from 'sweetalert2';

import Img from './Img'

let perguntaService = new PerguntaService();
let relatorioService = new RelatorioService();
let categoriaService = new CategoriaService();

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '100%',
    },
    width: '100%',
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  leftSpan: {
    width: '45px',
    display: 'inline-block'
  },
  leftPadding: {
    paddingLeft: '45px',
  },
  fontSmall: {
    fontSize: '0.85em'
  },
  spanClick: {
    cursor: 'pointer',
    fontSize: '1.1em',
  },
  spanNotaActive: {
    color: '#cbd44b',
    fontWeight: 'bold'
  },
  subtitle: { 
    marginBottom: '15px', 
    fontSize: '1.2em',
    fontWeight: '600'
  },
  
  divNotaItem: {
    width: '14.6%',
    display: 'inline-block',
    textAlign: 'center',
    margin: '0px',
    padding: '0px'
  }
}));

function Form(props) {
  let history = useHistory();
  const classes = useStyles();
  const { relatorioid, categoriaid, perguntaid } = props.match.params;

  const [state, setState] = useState({
    valid: false,
    relatorio: {},
    categoria: '',
    pergunta: null,

    nota: '',
    observacao: '',
    obsInitialValue: '',
    fotos: [],
    anexos: [],
    anexoEditing: '',
    count: 0,
    goingToNext: false
  });

  const [loadedState, loadedSetState] = useState(false);
  const [anexoName, setAnexoName] = useState('');
  

  if (!loadedState) {
    loadedSetState(true);
    var relatorio = relatorioService.findById(relatorioid);
    setState(prevState => ({ ...prevState, 'relatorio': relatorio }));
    
    //verifica se já carregou a categoria no relatório
    var categoria = null;
    var pergunta = null;
    var catsRelatorio = relatorio.categorias.filter(m => m.id == categoriaid);
    if (catsRelatorio.length > 0) {
      categoria = catsRelatorio[0];
      //buscar se já tem a pergunta cadastrada
      var pergsRelatorio = categoria.perguntas.filter(m => m.id == perguntaid);
      if (pergsRelatorio.length > 0) {
        pergunta = pergsRelatorio[0];
        setState(prevState => ({ ...prevState, 'fotos': pergunta.fotos.map(m => m.key) }));
        setState(prevState => ({ ...prevState, 'anexos': pergunta.anexos }));
        setState(prevState => ({ ...prevState, 'nota': pergunta.nota }));
        setState(prevState => ({ ...prevState, 'observacao': pergunta.observacao }));
        setState(prevState => ({ ...prevState, 'obsInitialValue': pergunta.observacao }));
      }
      setState(prevState => ({ ...prevState, 'categoria': categoria.nome }));
    } else {
      categoria = categoriaService.findById(categoriaid);
      setState(prevState => ({ ...prevState, 'categoria': categoria.nome }));
    }

    if (pergunta == null) {
      pergunta = perguntaService.findById(perguntaid);
    }
    setState(prevState => ({ ...prevState, 'pergunta': pergunta }));
  }

  let setValue = (name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }));
    relatorioService.salvarNotaObservaocaoPergunta(state.relatorio,
      categoriaid, perguntaid, name, value);
  }

  const lblPergunta = () => {
    if (state.pergunta == null) {
      return null;
    }
    return (
      <div>
        <InputLabel>
          <span className={classes.leftSpan}>
            <CheckIcon className={classes.labelIcon} style={{ fill: state.valid ? "#cbd44b" : "#696969", }} />
          </span>
          <span style={{ fontWeight: '600' }}>{state.pergunta.descricao}</span>
        </InputLabel>
      </div>
    )
  };

  const lblMetodoAnalise = () => {
    if (state.pergunta == null) {
      return null;
    }
    return (
      <div style={{ marginTop: '30px' }}>
        <div style={{ width: '45px', display: "inline-block" }}>&nbsp;</div>
        <div style={{ width: 'calc(100% - 45px)', display: 'inline-block' }}>
          <InputLabel>
            <span style={{ fontWeight: '600' }}>Método de Análise:</span> {state.pergunta.metodo}
          </InputLabel>
        </div>
      </div>
    )
  };
  
  const lblEvidenciaObjetiva = () => {
    if (state.pergunta == null) {
      return null;
    }
    return (
    <div style={{ marginTop: '30px' }}>
        <div style={{ width: '45px', display: "inline-block" }}>&nbsp;</div>
        <div style={{ width: 'calc(100% - 45px)', display: 'inline-block' }}>
          <InputLabel style={{ marginBottom: '15px' }}>
            <span style={{ fontWeight: '600' }}>Evidência Objetiva:</span>
          </InputLabel>
          {
            state.pergunta.evidencias.map((e,i,arr) => {
              return React.cloneElement(
                (<div style={{ marginBottom: '8px' }}><InputLabel className={classes.fontSmall}><Bullet style={{ marginRight: '10px' }}></Bullet> {e}</InputLabel></div>),
                {
                  key: `idxEvidencia${i}`
                }
              )
            })
          }
        </div>
      </div>
    )
  };
  
  const getBar = () => {
    if (state.nota != '' && state.nota != 'NA') {
      switch (state.nota) {
        case '0':
          return (<img src={require('../../../img/nota-0.png')} style={{ width: '100%' }} />);
        case '1':
          return (<img src={require('../../../img/nota-1.png')} style={{ width: '100%' }} />);
        case '2':
          return (<img src={require('../../../img/nota-2.png')} style={{ width: '100%' }} />);
        case '3':
          return (<img src={require('../../../img/nota-3.png')} style={{ width: '100%' }} />);
        case '4':
          return (<img src={require('../../../img/nota-4.png')} style={{ width: '100%' }} />);
        case '5':
          return (<img src={require('../../../img/nota-5.png')} style={{ width: '100%' }} />);
      }
    }
    return (<img src={require('../../../img/sem-nota.png')} style={{ width: '100%' }} />);
  }

  const divNota = () => { 
    if (state.pergunta == null) {
      return null;
    }
    var notas = [0, 1, 2, 3, 4, 5];
    return (
      <div style={{ marginTop: '30px' }}>
        <div style={{ width: '45px', display: "inline-block"}}>&nbsp;</div>
        <div style={{ width: 'calc(100% - 45px)', maxWidth: '450px', display: 'inline-block'}}>
          <div>
            <div>
              {
                notas.map((n,i,arr) => {
                  return React.cloneElement(
                    (<span className={`MuiFormLabel-root ${classes.divNotaItem} ${classes.spanClick} ${state.nota == n.toString() ? classes.spanNotaActive : ''}`} onClick={() => { setValue('nota', n.toString()) }}>{n}</span>),
                    {
                      key: `idxDivNota${i}`
                    }
                  )
                })
              }
              <span className={`MuiFormLabel-root ${classes.divNotaItem} ${classes.spanClick} ${state.nota == 'NA' ? classes.spanNotaActive : ''}`} onClick={() => { setValue('nota', 'NA') }} style={{ width: '12%' }}>N/A</span>
            </div>
            <div>
              {getBar()}
            </div>
          </div>
        </div>
        
      </div>
    )
  };

  const divObservacao = () => { 
    if (state.pergunta == null) {
      return null;
    }
    return (
      <div style={{ marginTop: '30px' }}>
        <div style={{ width: '45px', display: "inline-block" }}>&nbsp;</div>
        <div style={{ width: 'calc(100% - 45px)', display: 'inline-block' }}>
          <InputLabel className={classes.subtitle}>
            Observações:
          </InputLabel>
          <TextField
            style={{ width: '100%' }}
            multiline
            rows={5}
            variant="outlined"
            defaultValue={state.obsInitialValue}
            onKeyUp={(e) => {
              var v = e.target.value;
              setState(prevState => ({ ...prevState, 'observacao': v }));
              relatorioService.salvarNotaObservaocaoPergunta(state.relatorio,
                categoriaid, perguntaid, 'observacao', v);
            }}
          />
        </div>
      </div>
    );
  }

  const processAnexo = (file) => {
    var reader = new FileReader();
    reader.onload = base64 => {
      relatorioService.salvarAnexo(state.relatorio, categoriaid, perguntaid, file.name, base64.currentTarget.result, (key, arquivos) => {
        state.anexos = arquivos;
        setState(prevState => ({ ...prevState, 'anexos': state.anexos }));
        document.getElementById("inputFileAnexo").value = null;
        return Promise.resolve(true);
      });
    }
    reader.readAsDataURL(file);
  }

  const selectAnexo = async(files) => {
    if (files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        await processAnexo(files[i]);
      }
    }
  }

  const proccessImg = async (file) => {
    var reader = new FileReader()
    reader.onload = base64 => {
      relatorioService.salvarFotoPergunta(state.relatorio, categoriaid, perguntaid, null, base64.currentTarget.result, (key, fotos) => {
        state.fotos = fotos.map(m => m.key);
        setState(prevState => ({ ...prevState, 'fotos': state.fotos }));
        document.getElementById("inputFileFoto").value = null;
        return Promise.resolve(true);
      });
    }
    reader.readAsDataURL(file);
  }

  const selectImagem = async (files) => {
    if (files.length > 0) {
      var hasNaoPermitido = false;
      for (var i = 0; i < files.length; i++) {
        var f = files[i];
        if (!f.name.toUpperCase().endsWith(".PNG") && !f.name.toUpperCase().endsWith(".JPG") && !f.name.toUpperCase().endsWith(".JPEG")) {
          hasNaoPermitido = true;
        }
      }

      if (hasNaoPermitido) {
        ReactDOM.render(<Alerta severity={'error'} message={'Apenas imagens são aceitas (.png e .jpg)'} />, document.querySelector('#snack_root'));
        return;
      }

      for (var i = 0; i < files.length; i++) {
        await proccessImg(files[i]);
      }
    }
  }

  const excluirFoto = (idx) => { 
    Swal.fire({
      title: '',
      text: 'Deseja excluir a foto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        relatorioService.excluirFotoPergunta(state.relatorio, categoriaid, perguntaid, state.fotos[idx], (fotos) => {
          setState(prevState => ({ ...prevState, 'fotos': fotos.map(m => m.key) }));
        });
      }
    })
  };

  const editarFoto = (idx) => {
    history.push({
      pathname: `/inspecao/edit-picture/${relatorioid}/${categoriaid}/${perguntaid}/${state.fotos[idx]}`
    });
  };

  const openTakePhoto = () => {
    history.push({
      pathname: `/inspecao/take-picture/${relatorioid}/${categoriaid}/${perguntaid}`
    });
  };

  const divFotos = () => {
    if (state.pergunta == null) {
      return null;
    }
    return (
      <div style={{ marginTop: '30px' }}>
        <div style={{ width: '45px', display: "inline-block" }}>&nbsp;</div>
        <div style={{ width: 'calc(100% - 45px)', display: 'inline-block' }}>
          <input type="file" style={{ display: 'none' }} id="inputFileFoto" accept="image/png, image/jpeg" 
            onChange={(evt) => { selectImagem(evt.target.files) }} multiple={true} />
          <InputLabel className={classes.subtitle}>
            Adicionar Fotos
          </InputLabel>
          <div>
            <Camera onClick={() => { openTakePhoto() }} style={{ marginRight: '10px' }}></Camera>
            <SearchFile onClick={() => { document.getElementById("inputFileFoto").click() }} style={{ marginRight: '10px' }}></SearchFile>
            {
              state.fotos.map((f, i, arr) => {
                return React.cloneElement(
                  (
                    <div style={{ marginRight: '10px', padding: '2px', border: '1px #e0e0e0 dashed', display: 'inline-block', textAlign: 'center' }}>
                      <Img fotoKey={f}></Img>
                      <div style={{ textAlign: 'center' }}>
                        <IconButton edge="end" onClick={() => { editarFoto(i) }} style={{ marginRight: '2px', marginLeft: '2px' }}>
                          <CreateIcon className={classes.icon} />
                        </IconButton>
                        <IconButton edge="end" onClick={() => { excluirFoto(i) }} style={{ marginRight: '2px', marginLeft: '2px' }}>
                          <CloseIcon className={classes.icon} />
                        </IconButton>
                      </div>
                    </div>
                  ),
                  {
                    key: `idxFoto${f}`
                  }
                )
              })
            }
          </div>
        </div>
        
      </div>
    );
  }

  const deleteAnexo = (idx) => {
    Swal.fire({
      title: '',
      text: 'Deseja excluir o anexo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        relatorioService.deleteAnexo(state.relatorio, categoriaid, perguntaid, state.anexos[idx]);
        state.anexos.splice(idx, 1);
        setState(prevState => ({ ...prevState, 'anexos': state.anexos }));
      }
    })
  }

  const editAnexo = (fileKey) => {
    setState(prevState => ({ ...prevState, ['anexoEditing']: fileKey }));
    setAnexoName(relatorioService.getFileName(fileKey))
  }

  const saveAnexo = (fileKey) => {
    relatorioService.saveFileName(fileKey, anexoName);
    setState(prevState => ({ ...prevState, ['anexoEditing']: '' }));
    setAnexoName('')
  }

  const getAnexo = (index, fileKey) => {
    return (
      <div style={{ 'marginBottom': '10px', 'marginTop': '10px' }}>
        <Description color="action"></Description> 
        <span style={{ "marginLeft": "20px" }}>
          {
            state.anexoEditing == fileKey
            ? 
            (
              <TextField
                style={{ width: 'calc(100% - 170px)' }}
                variant="outlined"
                onChange={(event) => { setAnexoName(event.target.value) } }
                defaultValue={ relatorioService.getFileName(fileKey) }
              />
            )
            : relatorioService.getFileName(fileKey) 
          }
        </span>
        <span style={{ "float": "right" }}>
          {
            state.anexoEditing != fileKey ? 
              (
                <span>
                  <Edit onClick={() => editAnexo(fileKey)} style={{ marginRight: '3px' }}></Edit>
                </span>
              ) : 
              null
          }
          {
            state.anexoEditing == fileKey ? 
              (
                <span>
                  <Save onClick={() => saveAnexo(fileKey)} style={{ marginRight: '3px' }}></Save>
                </span>
              ) : null
          }
          <Delete onClick={() => deleteAnexo(index)}></Delete>
        </span>
        <div style={{clear: 'both'}}></div>
      </div>
    );
  }

  const divAnexos = () => {
    if (state.pergunta == null) {
      return null;
    }
    return (
      <div style={{ marginTop: '30px' }}> 
        <div style={{ width: '45px', display: "inline-block" }}>&nbsp;</div>
        <div style={{ width: 'calc(100% - 45px)', display: 'inline-block' }}>
          <div>
            <input type="file" style={{ display: 'none' }} id="inputFileAnexo" onChange={(evt) => { selectAnexo(evt.target.files) }} multiple={true} />
            <InputLabel className={classes.subtitle}>
              Anexos
            </InputLabel>
            <div>
              <NewFile onClick={() => { document.getElementById("inputFileAnexo").click() }}></NewFile>
            </div>
          </div>
          <div style={{ marginTop: '15px' }}>
          {
            
            state.anexos.map((a, i, arr) => { 
              return React.cloneElement(getAnexo(i, a), {
                key: `divAnexo${a}`
              })
            })
          }
          </div>
        </div>
      </div>
    );
  }

  const goNext = (cid, pid) => {
    history.push({
      pathname: `/inspecao/pergunta/${relatorioid}/${cid}/${pid}`
    });
    window.scrollTo(0, 0);
    setState(prevState => ({ ...prevState, 'goingToNext': true }));
    history.go();
  }

  const nextQuestion = () => {
    //carregar questoes da categoria
    var allCategorias = categoriaService.listAllCategorias();
    var allPerguntas = perguntaService.listAllRelatorio();

    var p1 = null;
    var idxC = allCategorias.indexOf(allCategorias.filter(m => m.id == categoriaid)[0]);
    var c1 = allCategorias[idxC];
    var perguntasCat = allPerguntas.filter(m => m.categoriaid == categoriaid);
    var idxP = perguntasCat.indexOf(perguntasCat.filter(m => m.id == perguntaid)[0]);

    while (p1 == null) {
      var isLastP = idxP == (perguntasCat.length - 1);
      //ultima pergunta da categoria, ir para a proxima categoria
      if (isLastP) {
        //proxima categoria
        var isLastCat = idxC == (allCategorias.length - 1);
        //ultima pergunta da ultima categoria (voltar lista de categorias/perguntas)
        if (isLastCat) {
          break;
        }

        //buscar próxima categoria
        idxC = idxC + 1;
        idxP = -1;

        c1 = allCategorias[idxC];
        perguntasCat = allPerguntas.filter(m => m.categoriaid == c1.id);

      } else {
        //proxima pergunta
        p1 = perguntasCat[idxP + 1];
      }
    }

    if (p1 != null) {
      if (state.nota == '' || (state.nota != 'NA' && state.nota != '5' && state.observacao.trim() == '')) {
        var msgs = '';
        if (state.nota == '') {
          msgs = 'Você ainda não informou a nota para a questão, tem certeza que deseja sair da tela?';
        } else {
          msgs = 'Você precisa informar uma observação para notas diferentes de 5, tem certeza que deseja sair da tela?';
        }
        Swal.fire({
          title: '',
          text: msgs,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sair',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.value) {
            goNext(c1.id, p1.id);
          }
        })
      } else {
        goNext(c1.id, p1.id);
      }
    } else {
      backToCategorias();
    }
  }

  const backToCategorias = () => {
    if (state.nota == '' || (state.nota != 'NA' && state.nota != '5' && state.observacao.trim() == '')) {
      var msgs = '';
      if (state.nota == '') {
        msgs = 'Você ainda não informou a nota para a questão, tem certeza que deseja sair da tela?';
      } else {
        msgs = 'Você precisa informar uma observação para notas diferentes de 5, tem certeza que deseja sair da tela?';
      }
      Swal.fire({
        title: '',
        text: msgs,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sair',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          history.push({
            pathname: `/inspecao/${relatorioid}`,
            state: state
          })
        }
      })
    } else {
      history.push({
        pathname: `/inspecao/${relatorioid}`,
        state: state
      })
    }
  }

  return (
    state.goingToNext ? 
      <div style={{textAlign: 'center', padding: '25px'}}><h4>Carregando próxima...</h4></div>
      : 
      <div>
        <Header progress="75" title="Checklist" subtitle={state.categoria} backTo={`/inspecao/${relatorioid}`} />
        <Grid container item xs={12} lg={12}>
          <Grid container item xs={11} lg={8} className="margin-auto">
            <form className={classes.root} noValidate autoComplete="off">
              {lblPergunta()}
              {lblMetodoAnalise()}
              {lblEvidenciaObjetiva()}
              {divNota()}
              {divObservacao()}
              {divFotos()}
              {divAnexos()}
            </form>
            <FormButton text={'Continuar Próxima Pergunta'} clicked={() => nextQuestion()} />
            <FormButton text={'Voltar Para Categorias'} clicked={() => backToCategorias()} />
          </Grid>
        </Grid>
      </div>

  );
}

export default Form;
