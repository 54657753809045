import React, { Component } from 'react';

import IconButton from "@material-ui/core/IconButton";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Crop169Icon from '@material-ui/icons/Crop169';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import UndoIcon from '@material-ui/icons/Undo';

class ActionBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            color: 'black',
            colors: ['black', 'red', 'green', 'yellow', 'blue', 'pink', 'orange', 'purple', 'crimson'],
            mode: ''
        }
    }

    setMode (mode) {
        this.setState(prevState => ({ ...prevState, 'mode': mode }))
        var options = {};
        switch (mode) {
            case 'text':
                options.placeholder = 'Texto';
                break;
            default:
                options.strokeWidth = 5;
                break;
        }
        this.props.editor.current.set(mode, options);
    }

    setColor(color) {
        var mode = this.state.mode;
        this.setState(prevState => ({ ...prevState, 'color': color }));
        this.props.editor.current.changeColor(color);
        this.setMode(mode);
    }

    render () {
        return (
            <div className="barActions">
                <div style={{ textAlign: 'center' }}>
                    {
                        this.state.colors.map((color, i) => { 
                            return (
                                React.cloneElement((
                                    <span style={{ borderRadius: '50%', marginRight: '8px', height: '30px', width: '30px', display: 'inline-block', backgroundColor: color }}
                                        onClick={() => { this.setColor(color) }} className={`${(this.state.color == color ? 'ab-color-selected' : '')}`}></span>
                                ), { key: `color${i}` 
                                }
                            )) 
                        })
                    }
                </div>
                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <IconButton edge="end" onClick={() => { this.props.editor.current.undo(); this.setMode(''); }} style={{ marginRight: '3px', marginLeft: '3px' }}
                        variant="contained" color={'default'}>
                        <UndoIcon />
                    </IconButton>
                    <IconButton edge="end" onClick={() => { this.setMode('text') }} style={{ marginRight: '3px', marginLeft: '3px' }} 
                            variant="contained" color={this.state.mode == 'text' ? 'primary' : 'default'}>
                        <TextFieldsIcon />
                    </IconButton>
                    <IconButton edge="end" onClick={() => { this.setMode('arrow') }} style={{ marginRight: '3px', marginLeft: '3px' }} 
                            variant="contained" color={this.state.mode == 'arrow' ? 'primary' : 'default'}>
                        <ArrowRightAltIcon />
                    </IconButton>
                    <IconButton edge="end" onClick={() => { this.setMode('rect') }} style={{ marginRight: '3px', marginLeft: '3px' }} 
                            variant="contained" color={this.state.mode == 'rect' ? 'primary' : 'default'}>
                        <Crop169Icon />
                    </IconButton>
                    <IconButton edge="end" onClick={() => { this.setMode('freeDrawing') }} style={{ marginRight: '3px', marginLeft: '3px' }} 
                            variant="contained" color={this.state.mode == 'freeDrawing' ? 'primary' : 'default'}>
                        <BorderColorIcon />
                    </IconButton>
                    <IconButton edge="end" onClick={() => { this.setMode('circle') }} style={{ marginRight: '3px', marginLeft: '3px' }} 
                            variant="contained" color={this.state.mode == 'circle' ? 'primary' : 'default'}>
                        <RadioButtonUncheckedIcon />
                    </IconButton>
                </div>
            </div>
        );
    }

}

export default ActionBar;