import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import Alerta from '../../common/Alert';
import ReactDOM from 'react-dom';

import '../../common/Main.css';
import Header from '../../common/Header';
import FormButton from '../../common/FormButton';

import { getAccessToken } from '../../../services/azureAd'
import RelatorioService from '../../../services/relatorioService.js';
let relatorioService = new RelatorioService();


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '100%',
    },
    width: '100%',
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
}));


function Envio () {
  const { id } = useParams();
  const classes = useStyles();
  let history = useHistory();

  const [state, setState] = useState({
    relatorio: null,
    enviando: 0,
    enviados: 0,
    total: 0,
    enviandoEmail: false,
    emailEnviado: false,
    houveErroEnvio: false,
    erroEnvioEmail: '',
    downloading: false
  });

  const [loadedState, loadedSetState] = useState(false);

  const sendFiles = async (relatorio, arquivosDados, arquivosBytes) => {

    for (var idx = 0; idx < arquivosDados.length; idx++) {
      setState(prevState => ({ ...prevState, 'enviando': (idx + 2) }));
      var f = arquivosDados[idx];
      f.bytes = arquivosBytes[f.key].file;
      await relatorioService.sendFile(f);
      setState(prevState => ({ ...prevState, 'enviados': (idx + 2) }));
    }
    
    getAccessToken().then((t) => { 
      setState(prevState => ({ ...prevState, 'enviandoEmail': true }));
      relatorioService.dispararEnvioEmail(relatorio._id, t.accessToken).then((r) => {
        if (r.data.status == 'error') {
          setState(prevState => ({ ...prevState, 'houveErroEnvio': true }));
          setState(prevState => ({ ...prevState, 'erroEnvioEmail': r.data.error }));
        }
        setState(prevState => ({ ...prevState, 'enviandoEmail': false }));
        setState(prevState => ({ ...prevState, 'emailEnviado': true }));
      });
    })

  }

  React.useEffect(() => {
    if (!loadedState) {
      loadedSetState(true);
      var relatorio = relatorioService.findById(id);
      var arquivos = []; 

      arquivos.push({
        key: relatorio.file_apr,
        name: relatorioService.getFileName(relatorio.file_apr),
        bytes: '',
        tipo: 'arquivoApr'
      });

      arquivos.push({
        key: relatorio.pdf_relatorio,
        name: relatorioService.getFileName(relatorio.pdf_relatorio),
        bytes: '',
        tipo: 'relatorioPdf'
      });

      //o json com o relatorio
      //o arquivo apr
      //o pdf do relatorio
      var qtdArquivos = 3;

      relatorio.anexos.forEach(a => {
        arquivos.push({
          key: a,
          name: relatorioService.getFileName(a),
          bytes: '',
          tipo: 'anexo'
        });
        qtdArquivos++;
      });

      relatorio.categorias.forEach(cat => {
        cat.perguntas.forEach(p => {
          p.anexos.forEach(a => {
            arquivos.push({
              key: a,
              name: relatorioService.getFileName(a),
              bytes: '',
              tipo: 'anexo'
            })
            qtdArquivos++;
          });

          p.fotos.forEach(f => {
            arquivos.push({
              key: f.key,
              name: relatorioService.getFileName(f.key),
              bytes: '',
              tipo: 'foto'
            });
            qtdArquivos++;
          });
        })
      });

      relatorio.codigosHomologados.forEach(a => {
        arquivos.push({
          key: a.key,
          name: relatorioService.getFileName(a.key),
          bytes: '',
          tipo: 'codigosHomologados'
        });
        qtdArquivos++;
      });

      relatorioService.getFiles(arquivos.map(m => m.key), (arquivosBytes) => {

        setState(prevState => ({ ...prevState, 'total': qtdArquivos }));
        setState(prevState => ({ ...prevState, 'relatorio': relatorio }));
        setState(prevState => ({ ...prevState, 'enviando': 1 }));

        relatorioService.enviarRelatorioJson(relatorio)
          .then(async (response) => {
            relatorioService.saveId(relatorio, response.data);
            setState(prevState => ({ ...prevState, 'enviados': 1 }));
            await sendFiles(relatorio, arquivos, arquivosBytes);
          })
          .catch((e) => {
            var error = e.toString();
            if (e.response && e.response.data) {
              error = JSON.stringify(e.response.data);
            } else if (e.response) {
              error = JSON.stringify(e.response);
            }
            ReactDOM.render(<Alerta severity={'error'} message={`Erro ao tentar enviar arquivos: ${error}`} />, document.querySelector('#snack_root'));
          });
      })
    }
  }, []);

  const getLoading = ()  => (
    <div style={{padding: '25px'}}>
      <h5 style={{ width: '100%', textAlign: 'center' }}>Aguarde... Carregando arquivos para envio</h5>
    </div>
  )
  
  const progress = () => {
    return (100.0/state.total) * state.enviados;
  }
  
  const getEnvioBar = () => (
    <div style={{ paddingTop: '10px' }}>
      <LinearProgressWithLabel value={progress()} />
      {
        state.total == state.enviados ? 
          null : 
          (<h4 style={{ marginTop: '10px', textAlign: 'center' }}>Enviando {state.enviando} de {state.total} arquivos</h4>)
      }
      
    </div>
  )
  
  const voltarHome = () => {
    history.push({
      pathname: `/home`
    });
  }
  
  const downloadRelatorio= () => {
    setState(prevState => ({ ...prevState, 'downloading': true }));
    getAccessToken().then((t) => { 
      relatorioService
        .downloadZip(state.relatorio._id, t.accessToken)
          .then((r) => {
            var link = window.document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([r.data]));
            link.download = 'relatorio_' + state.relatorio._id + '.zip';
            document.body.appendChild(link);
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            link.remove();
            window.URL.revokeObjectURL(link.href);
            setState(prevState => ({ ...prevState, 'downloading': false }));
          })
    })
  }

  return (
    <div>
      <Header progress="100" title="Enviar Relatório" disableBackTo={true} />
      <Grid container item xs={12} lg={12}>
        <Grid container item xs={11} lg={8} className="margin-auto">
          <form className={classes.root} height={(window.innerHeight - 250)}>
            {
              state.total == 0 ? (getLoading()) : (getEnvioBar())
            }
            {
              state.enviandoEmail ? 
                <h4 style={{ textAlign: "center", marginTop: "15px" }}>Enviando o email para o aprovador {state.relatorio.aprovador.id}...</h4>
              : null
            }
            {
              state.emailEnviado && !state.houveErroEnvio ?
                <h4 style={{ textAlign: "center", marginTop: "15px" }}>Email enviado para o aprovador {state.relatorio.aprovador.id}.</h4>
                : null
            }
            {
              state.emailEnviado && state.houveErroEnvio ?
                <h4 style={{ textAlign: "center", marginTop: "15px" }}>Erro ao tentar enviar email para o aprovador: { state.erroEnvioEmail }</h4>
                : null
            }
            
          </form>
          {

            (state.total > 0 && state.total === state.enviados && state.emailEnviado) || (state.houveErroEnvio) ?
              (
                <div style={{ width: '100%' }}>
                  <div style={{ width: '100%' }}>
                    <FormButton text={'Ir Para Home'} clicked={() => voltarHome()} />
                  </div>
                  {
                    !state.houveErroEnvio ? (
                      <div style={{ width: '100%' }}>
                        <FormButton text={'Download do Arquivo'} clicked={() => downloadRelatorio()} saving={state.downloading} />
                      </div>
                    ) : null
                  }
              </div>
                
              ) : null
          }
          
        </Grid>
      </Grid>
    </div>
  );
}

export default Envio;