
import BaseService  from './baseService.js';

export default class ClasseEquipService extends BaseService {

    constructor() {
        super('classes-equipamentos');
    }

    findById(id) {
        return this.findByIdDb(this.DbClassesEquip, id);
    }

    create(model) {
        return this.axiosCreate(
            'classe', 
            { _id: null, descricao: model.nome }, model,
            this.DbClassesEquip
        );
    }

    update (model) {
        return this.axiosUpdate(
            'classe',
            { _id: model.id, descricao: model.nome }, model,
            this.DbClassesEquip
        );
    }

    delete(id) {
        return this.axiosDelete(
            `classe/${id}`,
            id,
            this.DbClassesEquip
        );
    }

    load() {
        return this.getDb(this.DbClassesEquip);
    }

    async listAll (parametros = null) {
        return Promise.resolve(this.listAllActive(this.DbClassesEquip));
    }
}
