import React, { useState, useEffect } from 'react';

import RelatorioService from '../../../services/relatorioService.js';

let relatorioService = new RelatorioService();


function Img(props) {
  const [foto, setFoto] = useState('');
  
  useEffect(() => {
    relatorioService.getFoto(props.fotoKey, (file) => { setFoto(file); });
  }, []);
  

  return (
    foto == '' ? null :
      (<img src={foto} style={{ height: '140px' }}></img>)
  );
}

export default Img;
