import React from 'react';

import { Document, Page, View, Text, StyleSheet, Image, Font } from '@react-pdf/renderer'
import { Table, TableBody, TableCell, TableHeader, DataTableCell } from '@david.kucsai/react-pdf-table'
import Logo from './relatorio_topo.png'
import Check from './check.png';
import Uncheck from './uncheck.png';
import TrechucetMS from './trebuc.ttf';
import TrechucetMSBold from './trebucbd.ttf';

Font.register({ family: 'TrebuchetMS', src: TrechucetMS });
Font.register({ family: 'TrebuchetMS-Bold', src: TrechucetMSBold });

function Pdf (props) {
  const baseSize = 189;
  const styles = StyleSheet.create({
    document: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 35,
      paddingBottom: 35,
      fontSize: '12pt',
      fontFamily: 'TrebuchetMS'
    },
    imageTopo: {
      width: '210mm',
      height: 'auto',
      marginVertical: 0,
      marginHorizontal: 0,
      marginBottom: 5
    },
    thead: { 
      backgroundColor: '#009FC2', 
      textAlign: 'center',
      color: '#fff',
      fontFamily: 'TrebuchetMS-Bold',
      fontSize: '11pt'
    },
    head1: {
      fontSize: '11pt',
      fontFamily: 'TrebuchetMS-Bold',
      textAlign: 'center',
      width: '100%',
      marginBottom: 10
    }, 
    head2: {
      fontSize: '18pt',
      fontFamily: 'TrebuchetMS-Bold',
      textAlign: 'center',
      width: '100%',
      marginTop: 20,
      marginBottom: 20
    },
    cellTblTopo: {
      paddingTop: '5pt', 
      paddingBottom: '5pt',
      textAlign: 'center'
    },
    titulo: {
      fontSize: '16pt',
      marginBottom: 18,
      fontFamily: 'TrebuchetMS',
      color: '#2D74B5',
      paddingLeft: '40pt'
    },
    titulo2: {
      fontSize: '14pt',
      marginBottom: 13,
      fontFamily: 'TrebuchetMS',
      color: '#2D74B5',
      paddingLeft: '40pt'
    },
    sumTblCell: {
      color: '#000', 
      padding: 5
    },
    sumTblLbl: {
      backgroundColor: '#009FC2',
      color: '#ffffff',
      fontFamily: 'TrebuchetMS-Bold',
      fontSize: '11pt'
    },
    image: {
      //objectFit: 'cover',
    },
  });

  const getWH = (size, fator = 25.4) => {
    return ((fator/186) * size)+'mm'
  }

  const getDt = () => {
    return props.relatorio.dtRelatorio.split("-").reverse().join("/");
  }

  const getCapa = () => (
    <View style={[]}>
      <Image
        style={[styles.imageTopo, { marginTop: -70, marginLeft: -30 }]}
        src={Logo}
      />
      <Text style={[styles.head1, { marginTop: 50 }]}>VICE-PRESIDENCIA DE DISTRIBUIÇÃO - VPD</Text>
      <Text style={styles.head1}>GERÊNCIA TÉCNICA DE DISTRIBUIÇÃO - GTD</Text>
      <Text style={[styles.head2, { marginBottom: 30 }]}>{props.relatorio.tipoelab}</Text>

      <View style={{ width: '140mm', marginLeft: '23mm' }}>
        <Table data={[{ elab: props.relatorio.inspetor, aprov: props.relatorio.aprovador.descricao }, { elab: ' \n\n ', aprov: ' \n\n ' }]} >
          <TableHeader>
            <TableCell style={[styles.thead, styles.cellTblTopo]}>ELABORADO</TableCell>
            <TableCell style={[styles.thead, styles.cellTblTopo]}>APROVADO</TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell getContent={(r) => r.elab} style={[styles.cellTblTopo]} />
            <DataTableCell getContent={(r) => r.aprov} style={[styles.cellTblTopo]} />
          </TableBody>
        </Table>
      </View>
      
      <View style={{ width: '140mm', marginLeft: '23mm', marginTop: 200, textAlign: 'center' }}>

      </View>
    </View>
  )

  const getCalculoLabel = () => {
    var status = props.getStatus(props.relatorio);
    if (status == 'R') {
      return 'REPROVADO';
    }
    if (status == 'AR') {
      return 'APROVADO COM RESTRIÇÃO'
    }
    return 'APROVADO';
  }

  const getCalculoBg = () => {
    var status = props.getStatus(props.relatorio);
    if (status == 'R') {
      return '#ff0000';
    }
    if (status == 'AR') {
      return '#f79845'
    }
    return '#A9D08E';
  }

  const getCodigosHomologados = () => (
    props.relatorio.codigosHomologados != null && props.relatorio.codigosHomologados.length > 0 ? (
      <View style={{ width: `${baseSize}mm`, marginTop: 30 }}>
        <Text style={[styles.titulo, { marginBottom: 15 }]}>2. CODIGOS APROVADOS</Text>
        {
          props.relatorio.codigosHomologados.map(c => {
            return React.cloneElement((
              <Image src={props.fotos[c.key].file} style={[styles.image, { width: getWH(props.fotos[c.key].width), height: getWH(props.fotos[c.key].height), marginBottom: 15, marginLeft: 'auto', marginRight: 'auto' }]}></Image>
            ), { key: c.key })
          })
        }
      </View>
    ) : (
      null
    ) 
  );

  const getSumario = () => (
    <View style={[]}>
      <Text style={styles.titulo}>1. SUMÁRIO EXECUTIVO</Text>
      <View style={{ width: `${baseSize}mm`, backgroundColor: '#009FC2', color: '#ffffff', textAlign: 'center', padding: 5 }} wrap={false}>
        <Text style={{ color: '#fff', fontSize: '13pt', fontFamily: 'TrebuchetMS-Bold'}}>DADOS DO FORNECEDOR</Text>
      </View>
      <View style={{ width: `${baseSize}mm`, flexDirection: 'row' }}>
        <Text style={[styles.sumTblCell, styles.sumTblLbl, { width: `${48}mm` }]}>Empresa:</Text>
        <Text style={[styles.sumTblCell, { width: `${(baseSize - 48)}mm`, backgroundColor: '#EEECE1' }]}>{props.relatorio.nome}</Text>
      </View>
      <View style={{ width: `${baseSize}mm`, flexDirection: 'row' }}>
        <Text style={[styles.sumTblCell, styles.sumTblLbl,  { width: `${48}mm` }]}>Endereço:</Text>
        <Text style={[styles.sumTblCell, { width: `${(baseSize - 48)}mm`, backgroundColor: '#EEECE1' }]}>{props.relatorio.ende}</Text>
      </View>
      <View style={{ width: `${baseSize}mm`, flexDirection: 'row' }}>
        <Text style={[styles.sumTblCell, styles.sumTblLbl,  { width: `${48}mm` }]}>Contato:</Text>
        <Text style={[styles.sumTblCell, { width: `${83}mm`, backgroundColor: '#EEECE1' }]}>{props.relatorio.contato}</Text>
        <Text style={[styles.sumTblCell, styles.sumTblLbl,  { width: `${20}mm` }]}>Fone:</Text>
        <Text style={[styles.sumTblCell, { width: `${38}mm`, backgroundColor: '#EEECE1' }]}>{props.relatorio.fone}</Text>
      </View>
      <View style={{ width: `${baseSize}mm`, flexDirection: 'row' }}>
        <Text style={[styles.sumTblCell, styles.sumTblLbl, { width: `${48}mm` }]}>Material/Equipamento:</Text>
        <Text style={[styles.sumTblCell, { width: `${91}mm`, backgroundColor: '#EEECE1' }]}>{props.relatorio.tipoinspec}</Text>
        <Text style={[styles.sumTblCell, { backgroundColor: '#EEECE1', width: `${50}mm` }]}>{props.relatorio.especificacao}</Text>
      </View>
      
      <View style={{ width: `${baseSize}mm`, backgroundColor: '#ededed', 
          marginTop: 20, paddingBottom: 12, paddingTop: 12, 
          textAlign: 'center', fontSize: '11pt', fontFamily: 'TrebuchetMS-Bold' }}>
        <View style={{ width: '105mm', marginLeft: 'auto', marginRight: 'auto', flexDirection: 'row'}}>
          <View style={{ width: '38mm', textAlign: 'center', paddingTop: 6, paddingBottom: 6 }} >
            <Text>RESULTADO GERAL:</Text>
          </View>

          <View style={{ width: '67mm', backgroundColor: getCalculoBg(), paddingTop: 6, paddingBottom: 6, textAlign: 'center' }}>
            <Text>{props.getCalculoGeral(props.relatorio)}%&nbsp;&nbsp;&nbsp;&nbsp; {getCalculoLabel()}</Text>
          </View>

        </View>
      </View>

      <View style={{ width: `${baseSize}mm`, backgroundColor: '#009FC2', paddingBottom: 2, paddingTop: 2, textAlign: 'center' }}>
        <Text style={{ fontSize: '11pt', fontFamily: 'TrebuchetMS-Bold', color: '#ffffff' }}>RESULTADOS PARCIAIS</Text>
      </View>

      <View style={{ width: `${baseSize}mm`, backgroundColor: '#ededed', paddingBottom: 9, paddingTop: 9, textAlign: 'center', 
        fontSize: '11pt' }}>
        {
          props.relatorio.categorias.map(cat => {
            return React.cloneElement((
              <Text style={{ width: `${(Math.round(baseSize / 2))}mm`, textAlign: 'right', marginBottom: 3, marginTop: 3, fontSize: '9pt' }}>{cat.nome}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.getCategoriaCalculo(cat)}%</Text>
            ), { key: `catRes${cat.id}` })
          })
        }
      </View>

      { getCodigosHomologados() }


    </View>
  )
  
  const getFotos = (pergunta) => {
    var fotos = [];
    var i, j, chunk = 2;
    for (i = 0, j = pergunta.fotos.length; i < j; i += chunk) {
      fotos.push(pergunta.fotos.slice(i, i + chunk));
    }
    return fotos;
  }

  const getPergunta = (pergunta, index) => (
    <View style={{ width: `${baseSize}mm` }}>
      <View style={{ paddingLeft: 20, paddingRight: 20}}>
        <View style={{ flexDirection: 'row', marginTop: 12, marginBottom: 12, fontFamily: 'TrebuchetMS-Bold', fontSize: '13pt' }}>
          <View>
            <Text style={{ borderBottom: '1pt black solid' }}>Pergunta {(index + 1)}:</Text>
          </View>
          
          <View>
            <Text style={{ paddingLeft: 3, width: '154mm', textAlign: 'justify' }}>{pergunta.descricao}</Text>
          </View>
          
          
        </View>

        <View style={{ flexDirection: 'row', marginBottom: 12, fontSize: '12pt' }}>
          <Text style={{ fontFamily: 'TrebuchetMS-Bold' }}>Método de Análise: </Text>
          <Text style={{ fontFamily: 'TrebuchetMS', width: '143mm', textAlign: 'justify' }}> {pergunta.metodo}</Text>
        </View>

        <Text style={{ marginBottom: 12, fontFamily: 'TrebuchetMS-Bold', fontSize: '10pt' }}>Evidência Objetiva:</Text>

        {
          pergunta.evidencias.map((e, i, arr) => {
            return React.cloneElement(
              <Text style={{ marginBottom: 4, paddingLeft: 40, fontSize: '10pt', textAlign: 'justify' }}>&bull;&nbsp;&nbsp;{e}</Text>
              , {
                key: `evi${pergunta.id}${i}`
              });
          })
        }

        <View style={{ flexDirection: 'row', marginTop: 14, marginBottom: 18, fontSize: '12pt' }}>
          <Text style={{ fontFamily: 'TrebuchetMS-Bold' }}>Nota:&nbsp;&nbsp;</Text>

          <Image src={(pergunta.nota == '0' ? Check : Uncheck)} style={{ width: '5mm', height: '5mm' }}></Image>
          <Text style={{ fontSize: pergunta.nota == '0' ? '14pt' : '12pt', fontFamily: pergunta.nota == '0' ? 'TrebuchetMS-Bold' : 'TrebuchetMS' }}>&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;</Text>

          <Image src={(pergunta.nota == '1' ? Check : Uncheck)} style={{ width: '5mm', height: '5mm' }}></Image>
          <Text style={{ fontSize: pergunta.nota == '1' ? '14pt' : '12pt', fontFamily: pergunta.nota == '1' ? 'TrebuchetMS-Bold' : 'TrebuchetMS' }}>&nbsp;&nbsp;&nbsp;&nbsp;1&nbsp;&nbsp;&nbsp;&nbsp;</Text>

          <Image src={(pergunta.nota == '2' ? Check : Uncheck)} style={{ width: '5mm', height: '5mm' }}></Image>
          <Text style={{ fontSize: pergunta.nota == '2' ? '14pt' : '12pt', fontFamily: pergunta.nota == '2' ? 'TrebuchetMS-Bold' : 'TrebuchetMS' }}>&nbsp;&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;&nbsp;</Text>

          <Image src={(pergunta.nota == '3' ? Check : Uncheck)} style={{ width: '5mm', height: '5mm' }}></Image>
          <Text style={{ fontSize: pergunta.nota == '3' ? '14pt' : '12pt', fontFamily: pergunta.nota == '3' ? 'TrebuchetMS-Bold' : 'TrebuchetMS' }}>&nbsp;&nbsp;&nbsp;&nbsp;3&nbsp;&nbsp;&nbsp;&nbsp;</Text>

          <Image src={(pergunta.nota == '4' ? Check : Uncheck)} style={{ width: '5mm', height: '5mm' }}></Image>
          <Text style={{ fontSize: pergunta.nota == '4' ? '14pt' : '12pt', fontFamily: pergunta.nota == '4' ? 'TrebuchetMS-Bold' : 'TrebuchetMS' }}>&nbsp;&nbsp;&nbsp;&nbsp;4&nbsp;&nbsp;&nbsp;&nbsp;</Text>

          <Image src={(pergunta.nota == '5' ? Check : Uncheck)} style={{ width: '5mm', height: '5mm' }}></Image>
          <Text style={{ fontSize: pergunta.nota == '5' ? '14pt' : '12pt', fontFamily: pergunta.nota == '5' ? 'TrebuchetMS-Bold' : 'TrebuchetMS' }}>&nbsp;&nbsp;&nbsp;&nbsp;5&nbsp;&nbsp;&nbsp;&nbsp;</Text>

          <Image src={(pergunta.nota == 'NA' ? Check : Uncheck)} style={{ width: '5mm', height: '5mm' }}></Image>
          <Text style={{ fontSize: pergunta.nota == 'NA' ? '14pt' : '12pt', fontFamily: pergunta.nota == 'NA' ? 'TrebuchetMS-Bold' : 'TrebuchetMS' }}>&nbsp;&nbsp;&nbsp;&nbsp;N/A&nbsp;&nbsp;&nbsp;&nbsp;</Text>
        </View>
        {
          pergunta.observacao != '' ?
            (
              <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                <Text style={{ fontSize: '13pt', fontFamily: 'TrebuchetMS-Bold' }}>Observação:&nbsp;</Text>
                <Text style={{ fontSize: '12pt', fontFamily: 'TrebuchetMS', width: '154mm', textAlign: 'justify' }}>{pergunta.observacao}</Text>
              </View>
            ) : null
        }
      </View>
      
      
      {
        pergunta.fotos != '' && pergunta.fotos.length > 0 ?
          getFotos(pergunta).map((fotos, i) => { 
            return React.cloneElement((
              <View style={{ width: `${baseSize + 10}mm`, flexDirection: 'row', marginBottom: 10, textAlign: 'center', padding: 0, marginLeft: -18 }} wrap={true}>
                {
                  fotos.map(i => {
                    return React.cloneElement(
                      <Image src={props.fotos[i.key].file}
                        style={[styles.image, 
                          { 
                            height: getWH(props.fotos[i.key].height, fotos.length > 1 ? 18 : 25.4), 
                            width: getWH(props.fotos[i.key].width, fotos.length > 1 ? 18 : 25.4), 
                            marginBottom: 15, 
                            paddingLeft: 0,
                            paddingRight: 0,
                            marginLeft: 'auto', 
                            marginRight: 'auto' }]}></Image>,
                      {
                        key: `foto${i.key}`
                      }
                    );
                  })
                }
              </View>
            ), 
            {
              key: `fotos_g_${fotos[0].key}`
            })
          }): null
      }
      
    </View>
  )

  const getCategoria = (categoria, index) => (
    <View style={{ width: `${baseSize}mm` }}>
      <Text style={styles.titulo2}>3.{ (index + 1) }  {categoria.nome}</Text>
      <View style={{ fontSize: '13pt' }}>
        {
          categoria.perguntas.map((p, i, arr) => {
            return React.cloneElement(getPergunta(p, i), {
              key: `perg${p.id}`
            });
          })
        }
      </View>
    </View>
  )

  const getDesenvolvimento = () => (
    <View style={[]}>
      <Text style={[styles.titulo, { marginTop: 0 }]}>3. DESENVOLVIMENTO</Text>
      {
        props.relatorio.categorias.map((c, i, arr) => { 
          return React.cloneElement(getCategoria(c, i), {
            key: `cat${i}`
          });
        })
      }
    </View>
  )
const getTextComments = () => {
  var split = '\r\n';
  if (props.relatorio.comentarios.includes('\r\n')) {
    split = '\r\n';
  } else if (props.relatorio.comentarios.includes('\r')) {
    split = '\r';
  } else if (props.relatorio.comentarios.includes('\n')) {
    split = '\n';
  } 
  return props.relatorio.comentarios.split(split);
}
  const getConclusao = () => (
    <View>
      <View style={{ width: `${baseSize}mm`, marginBottom: 20 }}>
        <Text style={[styles.titulo, { paddingLeft: 0, paddingRight: 0 }]}>4. CONCLUSÃO</Text>
        <View style={{ width: `${baseSize}mm` }}>
          {
            getTextComments().map((txt, i, arr) => { 
            
              if (txt.trim() == '') {
                return null;
              }

              return React.cloneElement((
                <Text style={{ fontSize: '12pt', fontFamily: 'TrebuchetMS', lineHeight: 1.8, marginBottom: 10, textIndent: 30, textAlign: 'justify' }}>{txt}</Text>    
              ), 
              {
                key: `eleTxt${i}`
              })
            })
          }
          
        </View>
      </View>
    </View>
  )
  
  const getAnexos = () => (
    <View>
      <View>
        <Text style={[styles.titulo, { paddingLeft: 0, paddingRight: 0 }]}>5. ANEXOS</Text>
        {
          props.anexos.map(a => {
            return React.cloneElement((
              <View style={{ width: `${baseSize}mm`, flexDirection: 'row', marginBottom: 4 }}>
                <Text style={{ paddingLeft: 40, color: '#2D74B5' }}>&bull;</Text>
                <Text style={{ paddingLeft: 15 }}>{a.name}</Text>
              </View>
            ), { key: `idx${a.key}` })
          })
        }
        
      </View>
    </View>
  )

  return (
    <Document>
      <Page size="A4" style={styles.document}>
        <View>
          { getCapa() }
          <Text break></Text>
          { getSumario() }
          <Text break></Text>
          { getDesenvolvimento() }
          <Text break></Text>
          { getConclusao() }
          <Text break></Text>
          { getAnexos() }
        </View>
      </Page>
    </Document>
  );
}

export default Pdf;