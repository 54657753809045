import React from 'react';
import './Main.css';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Line } from 'rc-progress';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom'

let logo = require('./logo-small.png');

const useStyles = makeStyles({
  logo: {
    height: '45px',
  },
  header: {
    backgroundColor: '#f2f2f2',
    maxHeight: 120,
    minHeight: 120,
    position: 'relative',
    margin: 'auto'
  },
  headerIconLeft: {
    textAlign: 'left',
    margin: 'auto',
    padding: 10,
    paddingRight: 50,
  },
  headerIconCenter: {
    margin: 'auto',
    padding: 10,
  },
  headerIconRight: {
    margin: 'auto',
    padding: 10,
    paddingLeft: 50,
    textAlign: 'right'
  },
  title: {
    color: '#009fc2',
    fontWeight: 'bold',
    fontSize: '1.45em',
    textAlign: 'center'
  },
  subTitle: {
    color: '#C0C0C0',
    fontSize: "0.9em",
    textAlign: 'center'
  },
  backButtonIcon: {
    padding: 5,
    backgroundColor: '#fff',
    borderRadius: '50%',
    fill: "#C0C0C0",
    fontSize: 20,
    boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)'
  },
  progress: {
    position: 'absolute',
    bottom: 0,
  }
});

function Header(props) {

  let history = useHistory();
  const classes = useStyles();
  
  const back = () => {
    if (props.backTo != null && props.backTo != '') { 
      history.push(props.backTo);
    } else { 
      history.goBack(); 
    } 
  }

  return (
    <div>
      <Grid className={classes.header} container item xs={12} lg={12}>
        <Grid className={classes.header} container item xs={11} lg={8} >
          <Grid className={classes.headerIconLeft} item xs={3} lg={2}>
            {
              props.disableBackTo != null && props.disableBackTo == true ? null : <ArrowBackIcon className={classes.backButtonIcon} onClick={() => back()} />
            }
            
          </Grid>
          <Grid className={classes.headerIconCenter} item xs={6} lg={8}>
            <Box className={classes.title} component="span" display="block">
              {props.title}
            </Box>
            <Box className={classes.subTitle} component="span" display="block">
              {props.subtitle}
            </Box>
          </Grid>
          <Grid className={classes.headerIconRight} item xs={3} lg={2}>
            <Link to="/home">
              <img src={logo} className={classes.logo}  alt={'logo'}/>
            </Link>
          </Grid>
        </Grid>
        <Line className={classes.progress} percent={props.progress} strokeWidth="1" strokeColor="#cbd44b" style={{ height: 3, width: '100%' }} />
      </Grid>

    </div>
  );
}

export default Header;
