import React from 'react';
import { Redirect } from "react-router-dom";


function ClearDb () {
    //limpar bancos locais
    localStorage.clear();
    return (<Redirect to={"/"} />);
}

export default ClearDb;
