var graph = require('@microsoft/microsoft-graph-client');

function getAuthenticatedClient (accessToken) {
    // Initialize Graph client
    const client = graph.Client.init({
        defaultVersion: 'beta',
        // Use the provided access token to authenticate
        // requests
        authProvider: (done) => {
            done(null, accessToken.accessToken);
        }
    });


    return client;
}

export async function getUserDetails (accessToken) {
    const client = getAuthenticatedClient(accessToken);
    const user = await client.api('/me').get();
    if (user) {
        client
            .api('/me/photo/$value')
            .get()
        .then((blob) => {
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                localStorage.setItem('apprat_userphoto', reader.result)
            }
        })
        .catch((e) => {
            console.log('foto erro')
            console.log(e)
        })
    }
    return user;
}
