import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import '../common/Main.css';
import Header from '../common/Header';
import Listing from '../common/Listing';
import PerguntaService from '../../services/perguntaService';
import CategoriaService from '../../services/categoriaService';
let cadTitleProp = null;
function List(props) {

  let history = useHistory();
  let { categoriaid } = useParams();

  if (cadTitleProp == null) {
    if (history.location && history.location.state && history.location.state.categoriaTitle) {
      cadTitleProp = history.location.state.categoriaTitle;
    } else {
      let serviceCat = new CategoriaService();
      cadTitleProp = serviceCat.findById(categoriaid).nome;
    }
    
  }

  const [cadTitle, setTitle] = React.useState(cadTitleProp);
  let service = new PerguntaService()


  return (
    <div className="App">
      <Header progress="100" title={`${cadTitle} - Lista de Perguntas`} backTo="/home" />
      <Listing service={service} listText="descricao" 
        routeEditFull={`/perguntas-edit/${categoriaid}`} routeFullCreate={`/perguntas-create/${categoriaid}`}
        route="perguntas" parametrosList={{ categoriaid: categoriaid }} />
    </div>
  );
}

export default List;
