import React from 'react';
import '../common/Main.css';
import Header from '../common/Header';
import Listing from '../common/Listing';
import ClasseEquipService from '../../services/classeEquipService';

let service = new ClasseEquipService();


function List() {


  

  return (
    <div className="App">
      <Header progress="100" title="Listar Classes / Equipamentos" backTo="/home"/>
      <Listing service={service} listText="nome" route="classes-equipamentos"/>
    </div>
  );
}

export default List;
