import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import Aprovadores from '../aprovadores/Modal';
import AprovadoresService from '../../services/aprovadoresService';
import ListAllService from '../../services/listAllService';
import { userAgentApplication } from '../../services/azureAd';
import Alerta from '../common/Alert';

import { Grid, Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    pos: {
      marginBottom: 12,
    },
    list: {
        width: 250,
        borderTopLeftRadius: '130px',
    },
    fullList: {
        width: 'auto',
    },
    itemBtn: {
        borderRadius: 4,
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingRight: "0px",
        paddingLeft: "0px",
        fontSize: "15px",
        width: '300px',
        fontWeight: 'bold',
    }
});

function Menu() {
    const classes = useStyles();

    const loginCode = "login";

    const menuItems = [
        { code: 'configurar-categorias', label: 'Configurar Categorias' },
        { code: 'classes-equipamentos', label: 'Classes / Equipamentos' },
        { code: 'perguntas', label: 'Listar Perguntas' },
        { code: 'aprovadores', label: 'Aprovadores' },
        { code: 'updateAllData', label: 'Carregar Dados' },
        { code: loginCode, label: 'Sair' },
    ];

    const [state, setState] = useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
      user: JSON.parse(localStorage.getItem('apprat_userinfo')),
      userFoto: localStorage.getItem('apprat_userphoto')
    });

    const toggleDrawer = (anchor, open) => (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

    let history = useHistory();

    var onClickMenuItem = (code) => {
        let goTo = '/';
        if (code != loginCode) {
            if (code == 'aprovadores') {
                let service = new AprovadoresService();
                let aprovadoresdb = service.loadAprovadores();
                setState({ ...state, ['right']: false });
                ReactDOM.render(<Aprovadores aprovadores={aprovadoresdb} />, document.querySelector('#modal_root'));
                return;
            }
            else if (code == 'updateAllData') {
                new ListAllService().listAll()
                    .then((response) => {
                        ReactDOM.render(<Alerta severity={'success'} message={'Dados atualizados.'} />, document.querySelector('#snack_root'));
                    })
                    .catch((error) => {
                        ReactDOM.render(<Alerta severity={'error'} message={`Erro ao tentar atualizar os dados: ${error.message}.`} />, document.querySelector('#snack_root'));
                    });
                return;
            }
            else {
                goTo += code;
            }
        }
        if (code == loginCode) {
            userAgentApplication.logout();
        }
        history.push(goTo);
    }

    return (
        <div style={{ marginTop: 'auto', marginBlock: 'auto', marginRight: '20px' }}>
            <React.Fragment key='right'>
                <MenuIcon className="menu-icon-open" onClick={toggleDrawer('right', true)} style={{ fill: "#C0C0C0", fontSize: '25px', boxShadow: '2px 2px 5px 0px rgba(192,192,192,0.44)' }} />
                <SwipeableDrawer
                    anchor='right'
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                    onOpen={toggleDrawer('right', true)}
                >
                    <div
                        className={clsx(classes.list, { [classes.fullList]: true, })}
                        role="presentation"
                        onKeyDown={toggleDrawer('right', false)}
                        style={{ background: '#009fc2', height: '100%', padding: '40px', width: '270px' }}
                    >
                        <div>
                            <Grid container xl={12} justify="left" className="menu-content">
                                <CloseIcon className="menu-icon-close" onClick={toggleDrawer('right', false)} style={{ fill: "#C0C0C0", fontSize: '35px' }} />
                            </Grid>
                            <Grid container xl={12} justify="left" className="menu-content">
                                <Grid item xs={4} className="menu-user-avatar">
                                    {
                                        state.userFoto == null ? 
                                            <Avatar style={{ height: '90px', width: '90px' }} src="./assets/perfil.png" /> :
                                            <Avatar style={{ height: '90px', width: '90px' }} src={state.userFoto} />
                                    }
                                </Grid>
                                <Grid item xs={8} className="menu-user-info">
                                    <Box component="span" display="block" className="menu-text">
                                        {state.user.displayName}
                                    </Box>
                                    <Box component="span" display="block" className="menu-text">
                                        {state.user.jobTitle}
                                    </Box>
                                    <Box component="span" display="block" className="menu-text">
                                        {state.user.employeeId}
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        <List>
                            {menuItems.map((item) => (
                                <ListItem button key={item.code}>
                                    <Button
                                        style={{
                                            backgroundColor: `${item.code == loginCode ? '#cbd44b' : '#ffffff'}`,
                                            marginTop: `${item.code == loginCode ? '20px' : '0px'}`,
                                        }}
                                        className={classes.itemBtn}
                                        onClick={() => onClickMenuItem(item.code)}
                                        variant="contained">
                                        <span style={{
                                            color: `${item.code == loginCode ? '#ffffff' : '#009fc2'}`,
                                            textTransform: "capitalize"
                                        }}>{item.label}</span>
                                    </Button>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}

export default Menu;
